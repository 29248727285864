import React from 'react';
import './destinationList.css';
import Destination from '../../data/destination';
import { FaArrowAltCircleRight } from "react-icons/fa";

const DestinationList = () => {
    const handleDestinationClick = (id) => {
        const destination = Destination.find(dest => dest.id === id);
        if (destination) {
            const url = `/destination/${id}`;
            window.location.href = url; // Navigate to the URL in the same tab
        }
    };

    return (
        <div className="destinationcontainer1">
            <div className="destination-list1">
                {Destination.map((dest) => (
                    <div 
                        key={dest.id} 
                        className="destination-item1" 
                        onClick={() => handleDestinationClick(dest.id)}
                    >
                        <img src={dest.image} alt={dest.destinationTitle} className="destination-image1" />
                        <h2 className="destination-title1">
                            <a href={`/destination/${dest.id}`}>
                                {dest.destinationTitle}
                            </a>
                            <span className='goicon1'><FaArrowAltCircleRight /></span>
                        </h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DestinationList;
