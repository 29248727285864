import React from 'react';
import './Heading.css';

function Heading () {
  return (
    <div className='heading-container'>
      
      <h6>BOOK THE TRIP AND ENJOY YOUR JOURNEY</h6>
      <hr></hr>
      <h2>OUR PILGRIMAGE EXPEDITION!!</h2>


    </div>
  )
}

export default Heading
