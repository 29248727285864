import React from 'react';
import './ourservice.css';
import { FaHotel } from "react-icons/fa";
import { MdLibraryBooks, MdEmojiTransportation, MdCardTravel, MdAirportShuttle } from "react-icons/md";

const OurService = () => {
  return (
    <div className="services-outer">
      <div className="services-inner">
        <div className="services-title">
          <h2>Our Comprehensive Services</h2>
        </div>
        <div className="services-list">
          <div className="service">
            <FaHotel className="icon" />
            <p>Our Accommodations</p>
          </div>
          <div className="service">
            <MdLibraryBooks className="icon" />
            <p>Exclusive Experience</p>
          </div>
          <div className="service">
            <MdEmojiTransportation className="icon" />
            <p>Transportation</p>
          </div>
          <div className="service">
            <MdCardTravel className="icon" />
            <p>Travel Tips</p>
          </div>
          <div className="service">
            <MdAirportShuttle className="icon" />
            <p>Airport Pickup & Drop</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurService;
