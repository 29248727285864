import React from 'react';
import '../components/Blog/blog.css';
import blog from '../data/blog';
import BlogList from '../components/Blog/BlogList';

function Blog() {
    return (
        <div className="blog-section">
            <div className="sectiontitle">
                <h1>ABOUT RAMAYANA TRAIL</h1>
            </div>
            <div className="blog-container">
                {blog.map(post => (
                    <BlogList
                        key={post.id}
                        id={post.id}
                        src={post.image}
                        title={post.title}
                        description={post.description}
                        link={post.link}
                        content1={post.content1}
                        header1={post.header1}
                        content2={post.content2}
                        header2={post.header2}
                        content3={post.content3}
                        header3={post.header2}
                        content4={post.content3}
                        header4={post.header2}
                        content5={post.content3}
                        header5={post.header2}
                        content6={post.content3}
                        header6={post.header2}
                        content7={post.content3}
                    />
                ))}
            </div>
        </div>
    );
}

export default Blog;
