// components/BlogPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import blogData from '../data/blog';
import './BlogPage.css';

const BlogPage = () => {
    const { id } = useParams();
    const blogPost = blogData.find(post => post.id === parseInt(id));

    if (!blogPost) {
        return <h2>Post not found</h2>;
    }

    return (
        <div className="blog-page">
            <h1>{blogPost.title}</h1>
            <img src={blogPost.image} alt={blogPost.title} className="blog-page-image" />
            <p>{blogPost.description}</p>
            <p>{blogPost.content1}</p>
            <h4>{blogPost.header1}</h4>
            <p>{blogPost.content2}</p>
            <h4>{blogPost.header2}</h4>
            <p>{blogPost.content3}</p>
            <h4>{blogPost.header3}</h4>
            <p>{blogPost.content4}</p>
            <h4>{blogPost.header4}</h4>
            <p>{blogPost.content5}</p>
            <h4>{blogPost.header5}</h4>
            <p>{blogPost.content6}</p>
            <h4>{blogPost.header7}</h4>
            <p>{blogPost.content8}</p>
            {/* Add more content as needed */}
        </div>
    );
}

export default BlogPage;
