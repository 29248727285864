import React from 'react';
import Contact from '../components/Contact';



const ContactUsPage = () => {
  return (
    <>
      <Contact/>
    </>
  )
}
 export default ContactUsPage;