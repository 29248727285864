import React from 'react';
import './imagerotate.css';

import sliderimg1 from '../../assets/images/Swami ji/1C0A7123-scaled.jpg';
import sliderimg2 from '../../assets/images/Swami ji/1C0A7257-scaled.jpg';
import sliderimg3 from '../../assets/images/Swami ji/1C0A7632-scaled.jpg';
import sliderimg4 from '../../assets/images/Swami ji/1C0A7729-scaled.jpg';
import sliderimg5 from '../../assets/images/Swami ji/1C0A7860-scaled.jpg';
import sliderimg6 from '../../assets/images/Swami ji/IMG_8635-scaled.jpg';
import sliderimg7 from '../../assets/images/Swami ji/IMG_8940-scaled.jpg';
import sliderimg8 from '../../assets/images/Swami ji/SAN_7407-scaled.jpg';
import sliderimg9 from '../../assets/images/Swami ji/Swami-Govind-Dev-Giri-Maharajs-Mayurapathi-visit2-scaled.jpeg';
import videoBg1 from '../../assets/videos/swami-image-rotater-video.mp4';


const Imagerotate = () => {
  return (
    <div className='SGbody2'>
      <div className='video-bg1'>
          <video src={videoBg1} autoPlay loop muted />
      </div>
      <div className='SGslider-container'>
      <div className='SGcontent'>
          <p>
            His Holiness Swami Sri Govinda Dev Giri Ji Maharaj Embarks for Sri Lanka to Unveil the Sacred Ramayana Trails!
          </p>
        </div>
        <div className='SGslider'>
          <span style={{ '--i': 1 }}> <img src={sliderimg1} alt='' /></span>
          <span style={{ '--i': 2 }}> <img src={sliderimg2} alt='' /></span>
          <span style={{ '--i': 3 }}> <img src={sliderimg3} alt='' /></span>
          <span style={{ '--i': 4 }}> <img src={sliderimg4} alt='' /></span>
          <span style={{ '--i': 5 }}> <img src={sliderimg5} alt='' /></span>
          <span style={{ '--i': 6 }}> <img src={sliderimg6} alt='' /></span>
          <span style={{ '--i': 7 }}> <img src={sliderimg7} alt='' /></span>
          <span style={{ '--i': 8 }}> <img src={sliderimg8} alt='' /></span>
          <span style={{ '--i': 9 }}> <img src={sliderimg9} alt='' /></span>
        </div>
      </div>
    </div>
  );
}

export default Imagerotate;
