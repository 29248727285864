import img1 from '../assets/images/img2.jpg';
import img2 from '../assets/images/img3.jpg';
import img3 from '../assets/images/img4.jpg';
import img4 from '../assets/images/img5.jpg';
import banner1 from '../assets/images/pkgbanner/pkg1-banner.png';

import pkg_banner_img_1 from '../assets/images/PkgSliderImg/pkg_slider_1.jpg';
import pkg_banner_img_2 from '../assets/images/PkgSliderImg/pkg_slider_2.jpg';
import pkg_banner_img_3 from '../assets/images/PkgSliderImg/pkg_slider_3.jpg';
import pkg_banner_img_4 from '../assets/images/PkgSliderImg/pkg_slider_4.jpg';
import pkg_banner_img_5 from '../assets/images/PkgSliderImg/pkg_slider_5.jpg';
import pkg_banner_img_6 from '../assets/images/PkgSliderImg/pkg_slider_6.jpg';
import pkg_banner_img_7 from '../assets/images/PkgSliderImg/pkg_slider_7.jpg';
import pkg_banner_img_8 from '../assets/images/PkgSliderImg/pkg_slider_8.jpg';


const packagesData = [
  {
    id: 1,
    title: '4 Nights/5 Days Tour A',
    shortdescrip: `Detailed description about Adventure Package. Explore the sacred sites of the Ramayana Trail in Sri Lanka....`,
    description: `Detailed description about Adventure Package. Explore the sacred sites of the Ramayana Trail in Sri Lanka. 
    Discover ancient temples, picturesque landscapes, and immerse yourself in the epic journey of Lord Rama.`,
    price: '$1000',
    location: '4 places',
    duration: '4N/5D',
    "route": "route1", // This should be the route identifier
    placesBanner:[pkg_banner_img_1,pkg_banner_img_2,pkg_banner_img_3,pkg_banner_img_4],
    day1Title:'Arrival & Visit Munneswaram & Manavari in Chilaw',
    day1: `You will be warmly welcomed by our tour representatives upon your arrival at the airport.
    Arrival and transfer to Chilaw (Approximately 2 Hrs)
    Visit Munneswaram Hindu Temple & Manavari Hindu Temple – Chilaw
    Dinner & overnight stay in Chilaw`,

    day2Title:'Ramboda Hanuman Temple & Arrival to Nuwara Eliya',
    day2:`Check out and visit Ramboda Sri Bhaktha Hanuman Temple. (Approximately 5 Hrs)
    Arrival to Nuwara Eliya (Approximately 2 Hrs)
    Dinner & overnight stay in Nuwara Eliya Hotel`,

    day3Title:'Visit Gregory Lake, Sita Amman Temple, Ravana Falls, Divurumpola',
    day3:`Check Out and visit Gregory Lake (Time Spent 1 Hrs)
    Travelling To Sita Temple (Time Spent 1 Hrs)
    Visit to Sita Mata Agni Pareeksha Temple & Meditation Session (Time Spent 1 Hrs)
    Dinner & overnight in Hambanthota`,

    day4Title:'Ussangoda National Park, Rumassala',
    day4:`After breakfast leave the hotel and travel to Ussangoda (Approximately 30 Min)
    Explore Rumassala (Time Spent 1 Hr 30 Min)
    Check In Hotel in Unawatuna 
    Dinner & overnight in unawatuna`,

    day5Title:'Back to Colombo, Shopping, Departure to Airport',
    day5:`Check out the hotel & arriving to Colombo (Approximately 2 Hrs)
    Shopping in Busting City Colombo (Approximately 2 Hrs)
    Get ready to have a safe flight`,
    
    places: ['Manavari Kovil - Chillaw', 'Munneshwaram kovil Chilaw', 'Ramboda Hanuman Temple','Sita Eliya Temple', 'Ussangoda - Galle'],
    image: img1,
    banner:banner1
  },
  {
    id: 2,
    title: '6 Nights/7 Days Tour B',
    shortdescrip: `Embark on a captivating 7-day journey through Sri Lanka, uncovering the legendary sites of the Ramayana.....`,
    description: `Embark on a captivating 7-day journey through Sri Lanka, uncovering the legendary sites of the Ramayana. 
    This tour offers a blend of mythological significance, ancient history, and stunning landscapes, tracing the steps of Lord Rama and his epic adventure.`,
    price: '$1500',
    location: '8 places',
    duration: '6N/7D',
    "route": "route2", // This should be the route identifier

    placesBanner:[pkg_banner_img_1,pkg_banner_img_2,pkg_banner_img_3,pkg_banner_img_4],

    day1Title:'Embarking to Sri Lanka, Take Steps to Manavari & Munneshwaram kovil Chilaw ',
    day1: `You will be warmly welcomed by our tour representatives upon your arrival at the airport. From there, you will head directly to Chilaw to begin your spiritual journey.
    Arrival and transfer to Chilaw (Approximately 2 Hrs)
    Visit Munneswaram Hindu Temple & Manavari Hindu Temple – Chilaw (Time Spent 2 Hrs 30 Min)
    Dinner & overnight stay in Chilaw`,

    day2Title:'Visit to Mannar, Approaching to Adams Bridge',
    day2:`After breakfast, embark on a divine journey to Mannar's Adams Bridge. 
    Travelling to Mannar via Anuradhapura (Approximately 6 Hrs)
    Indulge in a delightful high tea experience amidst the ancient splendour of Anuradhapura
    Embark on an extraordinary journey to experience the mystical Adams Bridge. (Time Spent 3 Hrs)
    Check in hotel in Mannar and dinner`,

    day3Title:'Travel to Kandy, Anuradhapura City Drive, Rest up',
    day3:`Checkout the hotel and heading to Kandy via Anuradhapura
    Explore Anuradhapura's ancient marvels. (Time Spent 1 Hr)
    Reaching to Kandy to have dinner and overnight stay in hotel`,

    day4Title:'Ramboda Hanuman Temple, Visit Gregory Lake',
    day4:`Check out the hotel & visit to Ramboda sri Baktha Hanuman Temple (Time Spent 2 Hrs)
    Visit Gregory Lake & have refreshment (Time Spent 3 Hrs)
    Check In hotel In Nuwara Eliya 
    Dinner & Overnight Stay in the Hotel`,

    day5Title:'Visit Sita Eliya Temple, Sita Mata Agni Pareeksha Temple, Ravana Falls',
    day5:`Check out the hotel and visit to Sita Eliya Temple (Time Spent 1 Hr)
    Embark to place where Sita Mata’s Agni Pariksha was happened and Join For meditation session (Time Spent 1 Hr)
    Experience the wonder of Ravana falls 
    Check in hotel in Hamabanthota
    Have dinner & Overnight stay in the hotel`,

    day6Title:'Galle, Ussangoda, Galle Fort, Rumassala',
    day6:`After the Breakfast checkout the hotel & visit to ussangoda (Time Spent 30 Min)
    Explore The Historic Galle fort Freely (Time Spent 3 Hrs)
    Take steps to Rumassala Hill (Time Spent 30 Min)
    Check in to the hotel in Unawatuna 
    Having Dinner & Overnight Stay in hotel`,

    day7Title:'Colombo, Kelaniya Temple, Depart to airport',
    day7:`Check out the hotel & arrive to Colombo (Approximately 2 Hrs)
    Visit Vibeeshana Monument (Time Spent 30 Min)
    Shopping at bustling city colombo (Time Spent 1 Hr 45 Min)
    Depart to airport`,

    places: ['Manavari Kovil - Chillaw', 'Munneshwaram kovil Chilaw', 'Adams Bridge', 'Anuradhapura', 'Sita Eliya Temple', 'Ussangoda - Galle', 'Kelaniya Temple - Colombo'],
    image: img2,
    banner:banner1
  },
  {
    id: 3,
    title: '6 Nights/7 Days Tour C',
    shortdescrip: `Embark on a spiritual journey through Sri Lanka, tracing the footsteps of the epic Ramayana. 
    This 7-day tour offers....`,
    description: `Embark on a spiritual journey through Sri Lanka, tracing the footsteps of the epic Ramayana. 
    This 7-day tour offers a blend of historical exploration, religious devotion, and cultural immersion, focusing on sites linked to the Ramayana, especially in Trincomalee.`,
    price: '$1500',
    location: '7 places',
    duration: '6N/7D',
    "route": "route3", // This should be the route identifier

    placesBanner:[pkg_banner_img_1,pkg_banner_img_2,pkg_banner_img_3,pkg_banner_img_4],


    day1Title:'Embarking to Sri Lanka, Take Steps to Manavari & Munneshwaram kovil Chilaw ',
    day1: `You will be warmly welcomed by our tour representatives upon your arrival at the airport. From there, you will head directly to Chilaw to begin your spiritual journey.
    Arrival and transfer to Chilaw (Approximately 2 Hrs)
    Visit Munneswaram Hindu Temple & Manavari Hindu Temple – Chilaw (Time Spent 2 Hrs 30 Min)
    Dinner & overnight stay in Chilaw`,
 
    day2Title:'Anuradhapura ancient city, Reaching to Trincomalee',
    day2:`Check out the hotel and travel to Anuradhapura (Approximately 3 Hrs 30 Min)
    Check in to Trincomalee Hotel
    Dinner and overnight stay at the hotel`,

    day3Title:'Cisit Koneshwaram Kovil, Arrive to Kandy, Cultural Show ',
    day3:`After Breakfast Heading to Koneshwaram Kovil (Time Spent 2 Hrs)
    Check in Hotel in Kandy (Approximately 5 Hrs)
    Experience the cultural show in kandy 
    Dinner & stay in hotel`,

    day4Title:'Ramboda Hanuman Temple, Visit Gregory Lake',
    day4:`Check out the hotel & visit to Ramboda sri Baktha Hanuman Temple (Time Spent 2 Hrs)
    Visit Gregory Lake & have refreshment (Time Spent 3 Hrs)
    Check In hotel In Nuwara Eliya 
    Dinner & Overnight Stay in the Hotel`,

    day5Title:'Visit Sita Eliya Temple, Sita Mata Agni Pareeksha Temple, Ravana Falls',
    day5:`Check out the hotel and visit to Sita Eliya Temple (Time Spent 1 Hr)
    Embark to place where Sita Mata’s Agni Pariksha was happened and Join For meditation session (Time Spent 1 Hr)
    Experience the wonder of Ravana falls 
    Check in hotel in Hamabanthota
    Have dinner & Overnight stay in the hotel`,

    day6Title:'Galle, Ussangoda, Galle Fort, Rumassala',
    day6:`After the Breakfast checkout the hotel & visit to ussangoda (Time Spent 30 Min)
    Explore The Historic Galle fort Freely (Time Spent 3 Hrs)
    Take steps to Rumassala Hill (Time Spent 30 Min)
    Check in to the hotel in Unawatuna 
    Having Dinner & Overnight Stay in hotel`,

    day7Title:'Colombo, Kelaniya Temple, Depart to airport',
    day7:`Check out the hotel & arrive to Colombo (Approximately 2 Hrs)
    Visit Vibeeshana Monument (Time Spent 30 Min)
    Shopping at bustling city colombo (Time Spent 1 Hr 45 Min)
    Depart to airport`,

    places: ['Manavari Kovil - Chillaw', 'Munneshwaram kovil Chilaw', 'Koneshwaram Kovil', 'Ramboda Hanuman Temple','Sita Eliya Temple', 'Ussangoda - Galle', 'Kelaniya Temple - Colombo'],
    image: img3,
    banner:banner1
  },
  {
    id: 4,
    title: '8 Nights/9 Days Tour D',
    shortdescrip: `Embark on a spiritual journey through Sri Lanka, tracing the footsteps of the epic Ramayana. 
    This 7-day tour offers ....`,
    description: `Embark on a spiritual journey through Sri Lanka, tracing the footsteps of the epic Ramayana. 
    This 7-day tour offers a blend of historical exploration, religious devotion, and cultural immersion, focusing on sites linked to the Ramayana, especially in Trincomalee.`,
    price: '$1500',
    location: '8 places',
    duration: '8N/9D',
    "route": "route4", // This should be the route identifier

    placesBanner:[pkg_banner_img_1,pkg_banner_img_2,pkg_banner_img_3,pkg_banner_img_4,pkg_banner_img_5,pkg_banner_img_6,pkg_banner_img_7,pkg_banner_img_8],


    day1Title:'Embarking to Sri Lanka, Take Steps to Manavari & Munneshwaram kovil Chilaw ',
    day1: `You will be warmly welcomed by our tour representatives upon your arrival at the airport. From there, you will head directly to Chilaw to begin your spiritual journey.
    Arrival and transfer to Chilaw (Approximately 2 Hrs)
    Visit Munneswaram Hindu Temple & Manavari Hindu Temple – Chilaw (Time Spent 2 Hrs 30 Min)
    Dinner & overnight stay in Chilaw`,

    day2Title:'Visit to Mannar, Approaching to Adams Bridge',
    day2:`After breakfast, embark on a divine journey to Mannar's Adams Bridge. 
    Travelling to Mannar via Anuradhapura (Approximately 6 Hrs)
    Indulge in a delightful high tea experience amidst the ancient splendour of Anuradhapura
    Embark on an extraordinary journey to experience the mystical Adams Bridge. (Time Spent 3 Hrs)
    Check in hotel in Mannar and dinner`,

    day3Title:'Arrive to Trinco via Vauniya, Visit Koneshwaram Kovil',
    day3:`After Breakfast Heading to Koneshwaram Kovil (Time Spent 2 Hrs)
    Check in Hotel in Trincomalee 
    Enjoy Yourself on a breathtaking beach 
    Dinner & stay in hotel`,

    day4Title:'Kanniya hot wells, cultural show',
    day4:`After Breakfast check out the hotel 
    Visit to kanniya hot wells in trincomalee
    Experience the cultural show in kandy 
    Dinner & stay in hotel at Kandy`,

    day5Title:'Ramboda Hanuman Temple, Visit Gregory Lake',
    day5:`Check out the hotel & visit to Ramboda sri Baktha Hanuman Temple (Time Spent 2 Hrs)
    Visit Gregory Lake & have refreshment (Time Spent 3 Hrs)
    Check In hotel In Nuwara Eliya 
    Dinner & Overnight Stay in the Hotel`,

    day6Title:'Visit Sita Eliya Temple, Sita Mata Agni Pareeksha Temple, Ravana Falls',
    day6:`Check out the hotel and visit to Sita Eliya Temple (Time Spent 1 Hr)
    Embark to place where Sita Mata’s Agni Pariksha was happened and Join For meditation session (Time Spent 1 Hr)
    Experience the wonder of Ravana falls 
    Check in hotel in Hamabanthota
    Have dinner & Overnight stay in the hotel`,

    day7Title:'Galle, Ussangoda, Galle Fort, Rumassala',
    day7:`After the Breakfast checkout the hotel & visit to ussangoda (Time Spent 30 Min)
    Explore The Historic Galle fort Freely (Time Spent 3 Hrs)
    Take steps to Rumassala Hill (Time Spent 30 Min)
    Check in to the hotel in Unawatuna 
    Having Dinner & Overnight Stay in hotel`,

    day8Title:'Colombo, Kelaniya Temple, Depart to airport',
    day8:`Check out the hotel & arrive to Colombo (Approximately 2 Hrs)
    Visit Vibeeshana Monument (Time Spent 30 Min)
    Shopping at bustling city colombo (Time Spent 1 Hr 45 Min)
    Check in Colombo Hotel & Overnight stay in hotel`,

    places: ['Manavari Kovil - Chillaw', 'Munneshwaram kovil Chilaw','Adams Bridge', 'Koneshwaram Kovil', 'Ramboda Hanuman Temple','Sita Eliya Temple', 'Ussangoda - Galle', 'Kelaniya Temple - Colombo'],
    image: img4,
    banner:banner1
  },
  // Add more packages as needed
];

export default packagesData;

