import React from 'react';
import TripAdvisor from '../components/Review/Review.jsx';

const TripAdvisorPage = () => {
  return (
    <div>
      <TripAdvisor/>
    </div>
  )
}

export default TripAdvisorPage
