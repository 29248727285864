import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Ensure your CSS is imported here
import  Button  from '../Button'; 
import logo from '../../assets/images/navbar-logo.png'; // Adjust the path to your logo image


function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const handleDropdown = () => setDropdown(!dropdown);

    

    // for signup mobile button
    const [button, setButton] = useState(true);

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }
        else{
            setButton(true);
        }
    };

    // to make sure that the contact btn is not appear on the responsive view once the page is refreshed
    useEffect (() => {
        showButton();
    },[]);

    window.addEventListener('resize', showButton);


  return (
    <>
    <nav className='navbar'>
        <div className='navbar-container'>
            {/* nav bar icon and title */}
            <Link to="/" className='navbar-logo' onClick={closeMobileMenu}>
            <img src={logo} alt="Sita Rama" className="navbar-logo-img" />
            </Link>
            {/* nav bar mobile menu icon */}
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {/* pages to add in menu */}
                <li className='nav-item'>
                    <Link to='/' className='nav-links'onClick={closeMobileMenu}>
                        Home
                    </Link>
                </li>

                <li className='nav-item'>
                    <Link to='/' className='nav-links' onClick={handleDropdown}>
                        Packages <i className='fas fa-caret-down' />
                    </Link>
                    {/* drop down for nav menu */}
                    {dropdown && (
                    <ul className='dropdown-menu'>
                    <li>
                        <Link to='/package/1' className='dropdown-links' onClick={closeMobileMenu}>
                        4 Nights/5 Days
                        </Link>
                    </li>
                    <li>
                        <Link to='/package/2' className='dropdown-links' onClick={closeMobileMenu}>
                        6 Nights/7 Days I
                        </Link>
                    </li>
                    <li>
                        <Link to='/package/3' className='dropdown-links' onClick={closeMobileMenu}>
                        6 Nights/7 Days II
                        </Link>
                    </li>
                    <li>
                        <Link to='/package/4' className='dropdown-links' onClick={closeMobileMenu}>
                        8 Nights/9 Days
                        </Link>
                    </li>
                    {/* Add more packages as needed */}
                    </ul>
                    )}
                </li>
                <li className='nav-item'>
                    <Link to='/destination' className='nav-links'onClick={closeMobileMenu}>
                        Destinations
                    </Link>
                </li>

                <li className='nav-item'> 
                    <Link to='/blogs' className='nav-links'onClick={closeMobileMenu}>
                        Blog
                    </Link>
                </li>

                <li className='nav-item'>
                    <Link to='/about-us' className='nav-links'onClick={closeMobileMenu}>
                        About Us
                    </Link>
                </li> 
                

                <li className='nav-item'> 
                    <Link to='/contact-us-page' className='nav-links'onClick={closeMobileMenu}>
                        Contact Us
                    </Link>
                </li>

                 {/* Mobile "Reserve Now" button */}
                 <li className='nav-item mobile-reserve'>
                    <Link to='/reservenow' className='nav-links-mobile' onClick={closeMobileMenu}>
                        Reserve Now
                    </Link>
                </li>
            </ul>
            {button && <Button to='/reservenow' buttonStyle='btn--primary btn--medium '>Reserve Now</Button>}
        </div>
    </nav>
    </>
  );
}

export default Navbar
