// src/pages/Packages.js
import React from 'react';
import PackageList from '../components/PackageList';
import packagesData from '../data/packages';
import '../App.css';
import '../components/PackageList/PackageList.css';

function Packages() {
  return (
    <div className='packages'>
      <h1>Our Pilgrimage Expedition!</h1>
      <div className='packages__container'>
        <div className='packages__wrapper'>
          <ul className='packages__list'>
            {packagesData.map(pkg => (
              <PackageList
                key={pkg.id}
                id={pkg.id}
                src={pkg.image}
                text={pkg.title}
                location={pkg.location}
                duration={pkg.duration}
                places={pkg.places}
                // description={pkg.description}
                shortdescrip={pkg.shortdescrip}
                route={pkg.route}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Packages;
