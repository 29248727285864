import React from 'react';
import TripAdviser from '../components/TripAdvisor/tripAdvisor';

const TripAdvisor = () => {
  return (
    <div>
      <TripAdviser />
    </div>
  )
}

export default TripAdvisor
