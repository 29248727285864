import React from 'react';
import WhyChooseSitaRama from '../components/WhyChooseSitaRama/whyChooseSitaRama';

const WhySitaRamaPage = () => {
  return (
    <div>
      <WhyChooseSitaRama />
    </div>
  )
}

export default WhySitaRamaPage
