import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import packagesData from '../data/packages';
import './PackagePage.css';
import van from '../assets/images/pkg-van.jpeg';
import suv from '../assets/images/pkg-suv.jpeg';
import bus from '../assets/images/pkg-bus.jpeg';
import luxury from '../assets/images/pkg-luxury.jpeg';
import PkgInquiryForm from '../components/PkgInquiryForm/PkgInquiryForm';
// import GoogleMapComponent from '../components/PkgGoogleMap/PkgGoogleMap.js';

import Route1Map from '../components/PkgGoogleMap/PkgGoogleMap.js'; // Import your map components
import Route2Map from '../components/PkgGoogleMap/PkgGoogleMap2.js';
import Route3Map from '../components/PkgGoogleMap/PkgGoogleMap3.js';
import Route4Map from '../components/PkgGoogleMap/PkgGoogleMap4.js';
import { FaMapMarkedAlt, FaClipboardList, FaStar } from "react-icons/fa"; // Import icons from react-icons
import PkgSlider from '../components/PkgSlider/PkgSlider';
import PkgBannerSection from '../components/PkgBannerSection/PkgBannerSection.js';
import ReserveNowForm from '../components/ReserveNowForm/ReserveNowForm.js';
import reserveNowImage from'../assets/images/reservenow1.jpg';

function PackagePage() {
  const { id } = useParams();
  const packageDetail = packagesData.find(pkg => pkg.id === parseInt(id));
  const [activeTab, setActiveTab] = useState('itinerary'); // Initialize activeTab with 'itinerary'

  if (!packageDetail) {
    return <h1>Package not found</h1>;
  }

  const renderDayActivities = () => {
    const days = [];
    for (let i = 1; i <= 9; i++) {
      const dayKey = `day${i}`;
      const dayTitleKey = `day${i}Title`;
      if (packageDetail[dayKey]) {
        // Split day details into separate lines for bullet points
        const dayDetails = packageDetail[dayKey].split('\n');
        days.push(
          <div key={dayKey} className="day-activity">
            <h3>Day {i}: {packageDetail[dayTitleKey]}</h3> {/* Display day title */}
            <ul>
              {dayDetails.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </div>
        );
      }
    }
    return days;
  };

  const renderMapComponent = () => {
    switch (packageDetail.route) {
      case 'route1':
        return <Route1Map />;
      case 'route2':
        return <Route2Map />;
      case 'route3':
        return <Route3Map />;
      case 'route4':
        return <Route4Map />;
      default:
        return null;
    }
  };


  return (
    <div className="package-page">
      <div className="package-header">
        <img src={packageDetail.banner} alt={packageDetail.title} className="package-image" />
        {/* <PkgBannerSection packageDetail={packageDetail} /> Pass packageDetail as prop */}

        {/* <h1>{packageDetail.title}</h1> */}
      </div>
      <div className="package-content-wrapper">
        <div className="package-column package-column-left">
          <div className='package-details'>
            <h1>{packageDetail.title}</h1>
            <p>{packageDetail.description}</p>
            <p className="price">Price: {packageDetail.price}</p>
            <p>Duration: {packageDetail.duration}</p> 
          </div>

          <div className="package-nav">
            <button
              className={activeTab === 'itinerary' ? 'active' : ''}
              onClick={() => setActiveTab('itinerary')}
            >
              <span className="button-icon"><FaClipboardList /></span> View Itinerary
            </button>
            <button
              className={activeTab === 'highlights' ? 'active' : ''}
              onClick={() => setActiveTab('highlights')}
            >
              <span className="button-icon"><FaStar/></span> Highlights
            </button>
            <button
              className={activeTab === 'map' ? 'active' : ''}
              onClick={() => setActiveTab('map')}
            >
              <span className="button-icon"><FaMapMarkedAlt /></span> Route
            </button>
            
          </div>
          
          <div className="package-content">
            {activeTab === 'itinerary' && (
              <>
                <div className='itinerary'>
                  {renderDayActivities()}                
                </div>
              </>
            )}
            {activeTab === 'highlights' && (
              <div className='highlights'>
                <ul className="pkg-highlights-text">
                  {packageDetail.places.map((place, index) => (
                    <li key={index}>{place}</li>
                  ))}
                </ul>
              </div>
            )}
            
            {activeTab === 'map' && renderMapComponent()} {/* Render the map component based on the package route */}

          </div>
        </div>
        <div className="package-column package-column-right">
        <div className="transport-options">
            <h2>Transport Options</h2>
            <div className='transport-img-section'>
              <div className="transport-option">
                <img src={van} alt="van" />
                <p>Van</p>
              </div>
              <div className="transport-option">
                <img src={suv} alt="suv" />
                <p>SUV</p>
              </div>
              <div className="transport-option">
                <img src={bus} alt="bus" />
                <p>Bus</p>
              </div>
              <div className="transport-option">
                <img src={luxury} alt="luxury" />
                <p>Luxury</p>
              </div>
            </div>
          </div>
          <div className="enquiry-form">
            {/* <h2>Enquiry Form</h2> */}
            <PkgInquiryForm  layout ='vertical'/>
          </div>
        </div>
      </div>
      <div className='pkg-slider'>
        <PkgSlider/>
      </div>
      <div className='reserve-now-section'>
        <div className="reserve-banner">
          <img src={reserveNowImage} alt="Reserve Banner" className="reserve-banner-image" />
        </div>
        <div className="reserve-form">
          <ReserveNowForm />
        </div>
      </div>
      
    </div>
  );
}

export default PackagePage;
