import post1 from '../assets/images/post1.jpg';
import post2 from '../assets/images/post2.jpg';
import post3 from '../assets/images/post3.jpg';

const blog = [
    {
        id: 1,
        image: post1,
        title: 'Epic Saga of Rama and Sita: A Tale of Love, Devotion, and Triumph',
        description: 'The Ramayana, an ancient Indian epic, unfolds the timeless story of Rama and Sita, highlighting the triumph of love and duty over adversity.',
        content1: `As King Dasaratha of Ayodhya prepares to retire, he designates his son Rama, the seventh incarnation of Lord 
        Vishnu, as his rightful heir. However, his queen Kaikeyi, invoking two prior promises owed to her, persuades him to crown her son 
        Bharata instead. Fearing ill fortune, she insists that Rama be exiled to the forest for fourteen years. Reluctantly, the king agrees,
         and Rama, along with his devoted wife Sita and loyal brother Lakshmana, leaves behind the opulence of royal life for the simplicity 
         of the wilderness.`,
        content2: `The entrance of the enchantress Surpanakha, who falls in love with Rama, upends their tranquil exile. Lakshmana steps in and harms 
        the demoness when Rama rejects Surpanakha's approaches due to his unshakable attachment to Sita. Enraged and humiliated, Surpanakha 
        runs to her brother, the Rakshasa King of Lanka, Ravana. Ravana is captivated by Surpanakha's depiction of Sita's exceptional beauty
        and decides he must have her for himself. Ravana, posing as a nomadic hermit, kidnaps Sita and transports her to Lanka, causing Rama
        and Lakshmana to pursue her with great distress.`,
        content3:`With the help of Hanuman, the valiant monkey king, Rama and Lakshmana embark on a quest to rescue Sita. Hanuman, possessing the 
        ability to alter his size at will, leaps across vast distances, carrying Rama's ring as a token of identification. In Lanka, Hanuman
        discovers Sita in Ravana's lush garden and reveals his allegiance to Rama, offering solace to the captive queen. However, Ravana, 
        enraged by Hanuman's mission, orders his tail to be set ablaze. Escaping his captors, Hanuman retaliates by setting Lanka ablaze, 
        signaling the onset of a climactic confrontation.`,
        content4:`In a violent fight, Rama, Lakshmana, Hanuman, and their friends the monkeys besiege Lanka. After a furious battle, Rama defeats 
        Ravana and releases Sita from captivity. Sita's struggles are not yet done, despite her ordeal. She goes through the fire trial to 
        establish her purity because she was doubted about her loyalty throughout her captivity. Sita triumphs and sets herself free, but 
        residual social expectations cloud their reconciliation. Rama unwillingly breaks up with Sita, who takes sanctuary in the hermitage 
        of the sage Valmiki, due to pressure from the public opinion.`,
        content5: `In the tranquility of the forest, Sita gives birth to twin sons, Lava and Kusa, who grow into fine young men under Valmiki's 
        guidance. Fate eventually reunites the estranged family, as Rama embraces his divine heritage, leading to a poignant reconciliation 
        with his beloved sons and their resilient mother, Sita. The Ramayana thus stands as a testament to enduring love, devotion, and the 
        ultimate triumph of good over evil.`,
        link: '/blog/1'
    },
    {
        id: 2,
        image: post2,
        title: "Discovering the Mysteries of Adam's Bridge",
        description: `Adam's Bridge, also known as Rama's Bridge or Ram Setu, is a captivating formation that stretches between Pamban 
        Island near Rameswaram in India and Mannar Island off the northwestern coast of Sri Lanka.`,
        content1:`This chain of natural limestone shoals holds a 
        unique place in both geological studies and Hindu mythology, making it a site of immense cultural and historical significance.`,
        header1:'A Geological Marvel',
        content2: `Geologically speaking, Adam's Bridge is thought to have been a land bridge that connected Sri Lanka and India in the past but is 
        now under water. This 30-kilometer (18-mile) area, which is made mostly of limestone shoals, has long piqued the interest of 
        scientists and researchers. According to theories, the bridge may have developed thousands of years ago through natural processes 
        including coral development and sedimentation. This deposit adds to the rich tapestry of Earth's geological history by offering 
        evidence that it is, in fact, a remnant of a prehistoric land link, as demonstrated by satellite photography and other research.`,
        header2: `A Mythological Wonder`,
        content3: `Adam's Bridge is celebrated in Hindu mythology as Ram Setu, a bridge constructed by Lord Rama's Vanara (monkey) army. Lord Rama is a 
        major character in the ancient Indian epic, the Ramayana. The epic tells the story of Rama's journey to save his wife Sita, who was 
        kidnapped and transported to Lanka by the evil king Ravana. The Ramayana states that Rama gave the order for his Vanara army to 
        build a bridge that would connect them to Lanka, guided by the god Hanuman. It was rumored that the bridge was constructed of 
        floating stones bearing Rama's name, which miraculously enabled Rama and his comrades to traverse the ocean and set out on their 
        quest to save Sita.`,
        header3: `Bridging the Past and Present`,
        content4: `The dual significance of Adam's Bridge as both a geological and mythological marvel makes it a fascinating subject for exploration. 
        For devotees and believers, Ram Setu is a testament to the divine intervention and the extraordinary feats described in the Ramayana. 
        Pilgrims flock to Rameswaram and nearby areas to pay homage and immerse themselves in the spiritual aura of this sacred site.
        For scientists and historians, Adam's Bridge offers an intriguing glimpse into the planet's geological past and the ways natural 
        formations can intersect with cultural narratives. Ongoing studies and debates about the bridge's origins continue to captivate the 
        scientific community, shedding light on the dynamic processes that shape our world.`,
        header4: `A Cultural and Historical Treasure`,
        content5: `Adam's Bridge stands as a symbol of the rich cultural heritage and the deep-rooted myths that have shaped the Indian subcontinent. 
        Its enduring legacy is a testament to the power of storytelling and the human fascination with bridging the realms of reality and 
        legend. Whether one views it through the lens of faith or science, Adam's Bridge remains a site of wonder and reverence, inviting us 
        to explore the mysteries that lie at the intersection of nature and myth.`,
        content6: `As we delve into the history and significance of Adam's Bridge, we are reminded of the enduring power of ancient epics like the 
        Ramayana and the ways in which they continue to inspire and captivate us. This unique formation, suspended between land and sea, 
        past and present, stands as a bridge not only between two lands but also between the realms of the known and the unknown, inviting 
        us to ponder the mysteries of our world and the stories that define our cultural heritage.`,
        link: '/blog/2'
    },
    {
        id: 3,
        image: post3,
        title: 'Seetha Amman Temple: A Sacred Site in the Heart of Sri Lanka',
        description: 'Nestled in the picturesque town of Nuwara Eliya, Sri Lanka, the Seetha Amman Temple stands as a beacon of devotion and a symbol of the enduring legacy of Hindu mythology.',
        content1:`This temple holds immense significance in Hindu culture, primarily due to its 
        association with one of Hinduism's most revered epics, the Ramayana.`,
        header1: `Mythological Sanctuary`, 
        content2: `In Hindu legend, the Seetha Amman Temple is supposed to be the location where the demon king Ravana imprisoned Sita, the faithful 
        consort of Lord Rama. The story of Sita's kidnapping and subsequent incarceration amid the verdant, lush surroundings of what is now 
        Nuwara Eliya is told in the epic Ramayana. This location has great spiritual significance because it is said that Sita prayed hard to 
        Lord Rama for her liberation while she was imprisoned.`,
        header2: `Pilgrimage of Devotion`,
        content3: `Today, the Seetha Amman Temple serves as a revered pilgrimage site for devotees of Lord Rama and Sita. Hindu pilgrims from around 
        the world visit the temple to pay homage to Sita and seek blessings for marital harmony and fertility. Sita is revered as a symbol 
        of devotion, virtue, and unwavering faith in Hindu culture, and her story continues to inspire countless followers.`,
        header3: `Cultural and Religious Significance`,
        content4: `The Seetha Amman Temple represents a vital cultural link between Sri Lanka and the Indian subcontinent through the narrative of the 
        Ramayana. It stands as a testament to the profound influence of Hindu mythology and traditions on Sri Lankan culture. The temple not 
        only serves as a place of worship but also as a symbol of the shared cultural heritage that binds these two regions.`, 
        header4:`Rituals and Ceremonies`,
        content5: `Various rituals and ceremonies are performed at the Seetha Amman Temple to honor Sita and seek her blessings. Devotees offer prayers, 
        light lamps, and conduct special poojas (worship ceremonies) dedicated to Sita. These rituals are imbued with deep spiritual 
        significance, reflecting the enduring reverence for Sita's virtue and devotion.`,
        content6: `Beyond its religious importance, the Seetha Amman Temple attracts tourists from around the world who are interested in Hindu 
        mythology and cultural heritage. The temple's serene surroundings and historical significance make it a must-visit destination for 
        those seeking to delve deeper into the rich tapestry of Hindu mythology and the timeless tales of the Ramayana.
        Sacred site`,
        content7: `The Seetha Amman Temple in Nuwara Eliya, Sri Lanka, stands as a sacred site that transcends borders and connects cultures through 
        the timeless narrative of the Ramayana. As a place of pilgrimage and devotion, it continues to inspire and uplift devotees with the 
        enduring legacy of Sita's unwavering faith and virtue. For tourists and spiritual seekers alike, the temple offers a glimpse into 
        the profound cultural and religious heritage that defines this beautiful region.`,
        link: '/blog/3'
    }
]

export default blog;