import mannarImg1 from '../assets/images/Destinations/Adam.jpg';
import mannarImg2 from '../assets/images/Destinations/Adam’s-Bridge.jpg';
import trincoImg2 from '../assets/images/Destinations/koneswaram-kovil-Hikka-tranz-530X420.jpg';
import trincoImg1 from '../assets/images/Destinations/thirukoneswaram-temple-trincomalee-sri-lanka-ramayana-tour-ceylon-expeditions-8.jpg';
import trincoImg3 from '../assets/images/Destinations/kinniyaHotWater.jpg';
import nuwaraeliyaImg1 from '../assets/images/Destinations/seetha-amman-temple.jpg';
import ellaImg1 from '../assets/images/Destinations/LS_RavanaFalls_Mob_800x1000.jpg';
import galleImg1 from '../assets/images/Destinations/Rumassala_1920x700.jpg';
import colomboImg1 from '../assets/images/Destinations/Colombo.jpg';
import nuwaraeliyaImg2 from '../assets/images/Destinations/SitaAmman.jpeg';
import nuwaraeliyaImg3 from '../assets/images/Destinations/Haggala.jpg';
import nuwaraeliyaImg4 from '../assets/images/Destinations/diwrumpala temple.JPG';
import nuwaraeliyaImg5 from '../assets/images/Destinations/rambodaHanuman.jpeg';
import ellaImg2 from '../assets/images/Destinations/ravana cave.jfif';
import ellaImg3 from '../assets/images/Destinations/ravanaFalls.jpeg';
import galleImg2 from '../assets/images/Destinations/UssanGoda.jpeg';
import galleImg3 from '../assets/images/Destinations/rumassala.jpeg';
import galleImg4 from '../assets/images/Destinations/Galle-Fort.jpg';
import chilawImg2 from '../assets/images/Destinations/badrakali-1.jpg';
import chilawImg1 from '../assets/images/Destinations/muneshwaram.jpeg';
import chilawImg3 from '../assets/images/Destinations/manawari.JPG';
import colomboImg2 from '../assets/images/Destinations/kelaniya.jpg';
import kandyImg1 from '../assets/images/Destinations/kandy.jpeg';
import kandyImg2 from '../assets/images/Destinations/Dunuwilla-e1688538947409.jpg';
import dambullaImg1 from '../assets/images/Destinations/dabulla.jpg';
import dambullaImg2 from '../assets/images/Destinations/sigiriya-4.jpg';
import dambullaImg3 from '../assets/images/Destinations/Golden-Temple.jpg';
import anuradhapuraImg1 from '../assets/images/Destinations/Anuradhapura.jpeg';
import anuradhapuraImg2 from '../assets/images/Destinations/Mirisaweti Stupa.jpg';
import anuradhapuraImg3 from '../assets/images/Destinations/Sri Maha Bodhiya.jpg';
import anuradhapuraImg4 from '../assets/images/Destinations/Brazen Palace.jpg';
import anuradhapuraImg5 from '../assets/images/Destinations/Lankarama or Lankaramaya Stupa.jpg';
import anuradhapuraImg6 from '../assets/images/Destinations/ruwan.webp';
import anuradhapuraImg7 from '../assets/images/Thuparama Stupa..webp';
import anuradhapuraImg8 from  '../assets/images/Abhayagiri Stupa.jpg';
import anuradhapuraImg9 from '../assets/images/Jetavanarama Stupa..avif';
import mathaleImg1 from '../assets/images/Destinations/mathale.jpg';
import mathaleImg2 from '../assets/images/Destinations/Matale_aluviharaya.jpg';
import mathaleImg3 from '../assets/images/Destinations/Spice-Garden__Resampled.jpg';
import hambantotaImg1 from '../assets/images/Destinations/kathargama.jpg';
import hambantotaImg2 from '../assets/images/Destinations/Kataragama Devalaya.jpg';
import hambantotaImg3 from '../assets/images/Destinations/Ridiyagama Safari Park.webp';
import hambantotaImg4 from '../assets/images/Destinations/Hambantota Birds Park.jfif';


const destination = [
  {
    id: 1,
    destinationTitle: 'Mannar',
    image: mannarImg1,
    description: `Mannar, located in the northwestern part of Sri Lanka, is a town steeped in history and cultural diversity. 
    It serves as a significant cultural crossroads with a mix of Tamil, Muslim, and Sinhalese communities. Historically, Mannar 
    has been a pivotal trading hub, evidenced by its ancient archaeological sites and remnants of colonial influences. The town's 
    natural beauty is showcased by pristine beaches, including Talaimannar Beach and Mannar Island's Adam's Bridge, offering stunning 
    views of the Gulf of Mannar and opportunities for birdwatching. Mannar is also notable for its religious landmarks such as 
    Thiruketheeswaram Kovil and St. Sebastian's Church, highlighting its religious diversity and cultural heritage.`,

    subDest1Title: 'Adam’s Bridge',
    subDest1Image: mannarImg2,
    subDest1Description: `Adam's Bridge, also known as Rama's Bridge or Ram Setu, is a chain of natural limestone shoals connecting 
    Pamban Island near Rameswaram in India to Mannar Island off the northwestern coast of Sri Lanka. Geologically, it is believed to 
    be a former land connection between India and Sri Lanka, now submerged.
    In Hindu mythology, Adam's Bridge is identified as Ram Setu, a bridge built by the Vanara (monkey) army of Lord Rama, as described 
    in the ancient Indian epic, the Ramayana. According to the epic, Rama needed to rescue his wife, Sita, who was abducted by the demon 
    king Ravana and taken to Lanka. Under the guidance of the god Hanuman, the Vanara army constructed this bridge using floating stones 
    inscribed with Rama's name, allowing Rama and his army to cross the sea and rescue Sita.
    The bridge's connection to both geological and mythological contexts make it a site of significant cultural and historical interest.`,
  },
  {
    id: 2,
    destinationTitle: 'Trincomalee',
    image: trincoImg1,
    description: `Trincomalee is a port city on the North Eastern coast of Sri Lanka, renowned for its natural deep-water harbour and 
    historical significance as a center of maritime trade and religious pilgrimage.`,

    subDest1Title: 'Thirukoneswaram Kovil',
    subDest1Image: trincoImg2,
    subDest1Description: `Thirukoneswaram Kovil's origins trace back over two millennia, making it one of the oldest and most historically 
    significant Hindu temples in Sri Lanka. It is believed to have been built during the classical period, with references in ancient 
    Tamil literature and inscriptions.
    Dedicated to Lord Shiva, Thirukoneswaram is considered one of the Pancha Ishwarams, the five ancient abodes of Shiva in Sri Lanka. 
    It is a prominent site for Shaivite worship and attracts devotees who seek blessings and spiritual solace.
    The temple is a major pilgrimage site for Hindus, especially during the annual festival of Maha Shivaratri. Thousands of devotees 
    visit to perform rituals, offer prayers, and participate in religious ceremonies.
    Thirukoneswaram is a symbol of resilience and continuity of Hindu traditions in Sri Lanka. Despite periods of destruction and 
    restoration, the temple remains a testament to the enduring cultural and religious heritage of the Tamil Hindu community.
    The temple's architecture, featuring Dravidian style with intricate carvings and the iconic thousand-pillared hall, is a reflection of 
    the rich artistic and architectural traditions of Hindu culture.
    The temple is linked with various Hindu myths and legends, including those involving Lord Rama, Ravana, and the worship of Shiva. 
    These stories are integral to the religious and cultural narrative of the region.
    Thirukoneswaram Kovil's historical, spiritual, and cultural significance make it a vital part of Hindu heritage and an enduring symbol 
    of devotion and resilience.`,

    subDest2Title: 'Kanniya Hot Springs',
    subDest2Image: trincoImg3,
    subDest2Description: `Kanniya Hot Springs, located near Trincomalee in Sri Lanka, are a series of seven geothermal wells with varying 
    temperatures, historically significant and revered for their therapeutic properties and ancient links to Hindu rituals and legends.`,
  },
  {
    id: 3,
    destinationTitle: 'Nuwara Eliya',
    image: nuwaraeliyaImg1,
    description: `Nuwara Eliya, often referred to as "Little England," is a picturesque hill station in Sri Lanka known for its cool climate,
     lush greenery, and scenic tea plantations, offering a refreshing escape and serving as a popular tourist destination.`,

    subDest1Title: 'Seetha Amman Temple',
    subDest1Image: nuwaraeliyaImg2,
    subDest1Description: `The Seetha Amman Temple, located in Nuwara Eliya, Sri Lanka, holds significant importance in Hindu culture 
    primarily due to its association with the Ramayana, one of Hinduism's most revered epics. 
    According to Hindu mythology, Seetha Amman Temple is believed to be the place where Sita, the consort of Lord Rama, was held captive 
    by the demon king Ravana. It is said that Sita prayed to Lord Rama for her release during her captivity at this location.
    The temple is a pilgrimage site for devotees of Lord Rama and Sita. Hindu pilgrims visit the temple to pay homage to Sita and seek 
    blessings for marital harmony and fertility, as Sita is considered a symbol of devotion and virtue in Hindu culture.
    The temple represents an important cultural link between Sri Lanka and the Indian subcontinent through the Ramayana narrative. 
    It serves as a testament to the enduring influence of Hindu mythology and traditions in Sri Lankan culture.
    Various rituals and ceremonies are performed at Seetha Amman Temple to honor Sita and seek her blessings. These rituals include 
    offering prayers, lighting lamps, and conducting special poojas (worship ceremonies) dedicated to Sita.
    Beyond its religious significance, the temple attracts tourists from around the world who are interested in Hindu mythology and 
    cultural heritage.`,

    subDest2Title: 'Hakgala Botanical Garden – Ashok Vatika',
    subDest2Image: nuwaraeliyaImg3,
    subDest2Description: `Ashok Vatika is a prominent location in the Hindu epic Ramayana, which is believed to have been located in Sri 
    Lanka. According to the Ramayana, Ashok Vatika was a beautiful garden situated in the kingdom of Lanka, ruled by the demon king Ravana. 
    Here are some key details and significance of Ashok Vatika:
    Ashok Vatika is traditionally believed to have been located in the area of present-day Sita Eliya (or Sita Amman Temple) in Nuwara 
    Eliya, Sri Lanka. This location is surrounded by lush greenery and is known for its serene and picturesque landscapes.
    According to the Ramayana, after Ravana abducted Sita, he kept her captive in Ashok Vatika. The garden was described as a paradise 
    adorned with various exotic flowers, fruit-bearing trees, and medicinal plants. Despite her captivity, Sita maintained her devotion to 
    Lord Rama and refused Ravana's advances.
    In the Ramayana, Hanuman, the devoted follower of Lord Rama and a prominent character in the epic, discovered Sita's whereabouts in 
    Ashok Vatika during his search for her. He disguised himself and infiltrated the garden, where he met Sita and reassured her of Rama's 
    imminent rescue.
    Ashok Vatika holds significant cultural and religious importance for Hindus. It is revered as the place where Sita displayed her 
    unwavering devotion to Lord Rama and where important events from the Ramayana unfolded. The garden symbolizes themes of love, devotion, 
    and the triumph of good over evil, which are central to Hindu philosophy and mythology.
    Today, the site traditionally associated with Ashok Vatika in Nuwara Eliya, Sri Lanka, attracts tourists and pilgrims who visit to pay 
    homage to Sita and explore the garden's historical and mythological connections. It serves as a reminder of the enduring legacy of the 
    Ramayana and its impact on the cultural heritage of Sri Lanka.`,

    subDest3Title: 'Divurumpola Temple',
    subDest3Image: nuwaraeliyaImg4,
    subDest3Description: `Divurumpola Temple, also known as Divurumpola Raja Maha Viharaya, is steeped in legend and mythology primarily 
    from the Hindu epic Ramayana. According to Hindu mythology, Divurumpola Temple is believed to be the location where Sita, after being 
    rescued from captivity by Lord Rama, underwent the trial by fire (Agni Pariksha) to prove her purity and fidelity. It is said that Sita 
    entered the flames unscathed, thus vindicating her chastity and integrity.
    The temple's association with the Ramayana makes it a significant pilgrimage site for Hindus. Pilgrims perform rituals and offer prayers
    at the temple to seek Sita's blessings and to commemorate her ordeal and subsequent vindication. Special ceremonies, including lighting 
    lamps and tying threads (similar to Raksha Bandhan rituals), are common practices among devotees. The temple is situated amidst serene
    natural surroundings, adding to its spiritual ambiance. It features traditional Sri Lankan temple architecture and is often adorned with
     vibrant decorations during festive occasions.`,

    subDest4Title: 'Ramboda Sri Baktha HanumanTemple',
    subDest4Image: nuwaraeliyaImg5,
    subDest4Description: `The Ramboda Sri Baktha Hanuman Temple, located near Ramboda Falls in Sri Lanka, is dedicated to Lord Hanuman, 
    a central figure in the Hindu epic Ramayana. Here's more about its significance:
    The temple is renowned for its association with Hanuman, who played a pivotal role in the Ramayana as the devoted disciple of Lord Rama.
    According to legend, Hanuman flew from India to Sri Lanka in search of Sita, Rama's consort who was abducted by the demon king Ravana. 
    The Ramboda area is believed to be one of the locations where Hanuman searched for Sita.
    Ramboda Sri Baktha Hanuman Temple is considered sacred by Hindus, particularly devotees of Hanuman. The temple features traditional 
    South Indian temple architecture, with colorful sculptures and paintings depicting scenes from the Ramayana and the life of Hanuman. `,
  },
  {
    id: 4,
    destinationTitle: 'Ella',
    image: ellaImg1,
    description: `Ella is a charming town in Sri Lanka's central highlands, known for its beautiful scenery and cool weather. 
    Highlights include the stunning views from Ella Rock and Little Adam's Peak. The Nine Arches Bridge is a popular spot, famous 
    for its unique design. Visitors can also enjoy Ravana Falls, old temples, and tea plantations. The town center has cozy cafes, 
    local restaurants, and small shops, offering a mix of nature and culture. Ella is perfect for hiking, relaxing, and enjoying the 
    peaceful surroundings.`,

    subDest1Title: 'Ravana Cave',
    subDest1Image: ellaImg2,
    subDest1Description: `Ravana Cave, located in Ella is a site steeped in myth and historical significance. The Cave is situated near 
    the picturesque town of Ella and is a modest-sized natural formation set into a cliff face, accessible via a trek that involves climbing
    around 600 steep steps through lush forest.
    The cave is around 1,370 meters above sea level, providing visitors with stunning views of the surrounding landscape. Inside, the cave 
    is relatively small and dark, with narrow passageways that open into larger chambers. Archaeological excavations have revealed evidence 
    of human habitation dating back to prehistoric times, suggesting that the cave has been used for thousands of years.
    Ravana Cave is named after King Ravana, a prominent figure in Hindu mythology, particularly in the epic Ramayana. According to the 
    Ramayana, Ravana, the ten-headed demon king of Lanka, abducted Sita, the wife of Lord Rama, and hid her in a secret location in his 
    kingdom. Local lore holds that Ravana used this cave to conceal Sita. It is believed that she was kept hidden here for a while before 
    being moved to other locations. The cave and the surrounding area are thought to have been part of King Ravana's vast empire, which 
    stretched across Lanka.
    Another legend states that Ravana’s body was hidden in the cave after he was defeated by Lord Rama. This adds a layer of mystique and 
    reverence to the site, making it a significant point of interest for those interested in the epic's lore.`,

    subDest2Title: 'Ravana Falls',
    subDest2Image: ellaImg3,
    subDest2Description: `RThe Ravana falls, also known as Ravana Ella, holds significance in Hindu mythology primarily due to its 
    association with the legendary figure Ravana from the epic Ramayana. According to local folklore and some interpretations of the 
    Ramayana, it is said that Ravana had palaces and gardens in the vicinity of this waterfall.
    For Hindus, especially those who revere the Ramayana, Ravana Ella serves as a cultural and spiritual landmark. There are various 
    local legends and traditions associated with Ravana Ella. Some stories suggest that Ravana used to bathe in these waterfalls or 
    that it was an area he frequented for leisure due to its scenic beauty. Beyond its mythological connections, Ravana Ella has become a 
    tourist attraction in Sri Lanka, drawing visitors who are interested in its natural beauty and the stories surrounding it.`,
  },
  {
    id: 5,
    destinationTitle: 'Galle',
    image: galleImg1,
    description: `Galle, situated on the southwestern coast of Sri Lanka, is a charming city known for its rich history, Dutch colonial 
    architecture, and vibrant cultural scene. The city's centerpiece is the historic Galle Fort, a UNESCO World Heritage site built by the 
    Portuguese in the 16th century and later fortified by the Dutch. Within the fort walls, visitors can explore cobblestone streets lined 
    with quaint cafes, art galleries, boutiques, and colonial-era buildings that reflect Galle's maritime past. Beyond its historical 
    allure, Galle boasts picturesque beaches, including Unawatuna Beach, renowned for its crystal-clear waters and coral reefs, making 
    it a popular destination for both cultural exploration and coastal relaxation in Sri Lanka.`,

    subDest1Title: 'Ussangoda',
    subDest1Image: galleImg2,
    subDest1Description: `Ussangoda is a coastal area located in Sri Lanka's Hambantota District, known for its unique geological features, 
    cultural significance, and intriguing legends.
    Ussangoda is characterized by its unusual reddish soil and barren landscape, contrasting sharply with the surrounding greenery, making 
    it a distinctive natural attraction in the region.
    According to local folklore, Ussangoda is believed to be the site where Ravana's aircraft, known as Dandu Monara (Flying Peacock), 
    crashed after his failed attempt to abduct Sita. This legend adds mythological significance to the area, connecting it with events 
    from the Ramayana epic.
    Archaeological excavations in Ussangoda have unearthed artifacts dating back to prehistoric times, shedding light on ancient human 
    habitation and cultural practices in the area.
    Despite its barren appearance, Ussangoda supports a unique ecosystem with several endemic plant species adapted to the harsh conditions 
    of the coastal plains.`,

    subDest2Title: 'Rumassala Sanjeevani Drops',
    subDest2Image: galleImg3,
    subDest2Description: `According to the Ramayana, when Lakshmana, younger brother of Lord Rama, was severely wounded in battle with the 
    demon king Ravana's forces, Hanuman was sent to fetch the Sanjeevani herb from the Himalayas. The herb had the miraculous ability to 
    revive and heal wounds, even from the brink of death.
    Hanuman, the devoted disciple of Lord Rama and a powerful deity in Hindu mythology, flew to the Himalayas in search of the Sanjeevani 
    herb. Unable to identify the exact herb, he uprooted the entire mountain and brought it back to Lanka, where the physicians were able 
    to extract the herb to revive Lakshmana.
    The Sanjeevani herb symbolizes divine healing and the power of devotion in Hindu mythology. It highlights the selfless service and 
    unwavering devotion of Hanuman towards Lord Rama and his family.
    The legend of the Sanjeevani herb is revered by Hindus as a testament to the potency of divine grace and the belief in the healing 
    powers of nature. It underscores the importance of faith, courage, and perseverance in overcoming challenges and restoring health and 
    well-being.
    Today, the story of the Sanjeevani herb continues to inspire devotion and reverence among Hindus. It is also metaphorically used to 
    describe any miraculous remedy or solution that brings about healing and renewal.`,

    subDest3Title: 'Galle Fort',
    subDest3Image: galleImg4,
    subDest3Description: `Galle Fort, located on the southwest coast of Sri Lanka, is a UNESCO World Heritage Site and a captivating blend 
    of history and culture. Originally built by the Portuguese in 1588 and later fortified by the Dutch in the 17th century, this ancient 
    fort stands as a testament to colonial architecture and maritime heritage. Encircled by thick stone walls, the fort houses a quaint town
     with narrow streets, Dutch-style villas, and iconic landmarks such as the Galle Lighthouse, the Dutch Reformed Church, and the Maritime 
     Museum. The fort's cobbled streets are lined with boutique shops, cafes, and art galleries, offering a unique blend of old-world charm 
     and modern vibrancy. Overlooking the Indian Ocean, Galle Fort remains a prominent historical and tourist destination, showcasing the 
     rich cultural tapestry and colonial legacy of Sri Lanka.`,
  },
  {
    id: 6,
    destinationTitle: 'Chilaw',
    image: chilawImg1,
    description: `Chilaw is a coastal town in Sri Lanka known for its vibrant fish markets, pristine beaches, and historic architecture, 
    making it a popular destination for both locals and tourists.`,

    subDest1Title: 'Muneeshwaram',
    subDest1Image: chilawImg2,
    subDest1Description: `Munneswaram Temple, located in Chilaw, Sri Lanka, is a prominent Hindu temple dedicated to Lord Shiva, revered 
    for its historical significance, vibrant festivals, and cultural heritage attracting devotees and visitors alike.`,

    subDest2Title: 'Manavari',
    subDest2Image: chilawImg3,
    subDest2Description: `Manavari is a sacred Hindu temple located near Chilaw in Sri Lanka, dedicated to Lord Rama. It is believed to be 
    the first temple in the world dedicated to Lord Rama's consort, Sita, where she is said to have prayed for Rama's rescue after being 
    held captive by King Ravana in the Ramayana epic.`,
  },
  {
    id: 7,
    destinationTitle: 'Colombo',
    image: colomboImg1,
    description: `Colombo is the commercial capital city of Sri Lanka, known for its bustling markets, colonial architecture, and diverse 
    cultural heritage, offering a blend of modernity and historical charm alongside scenic coastal views.`,

    subDest1Title: 'Kelaniya Buddhist Temple',
    subDest1Image: colomboImg2,
    subDest1Description: `Kelaniya Buddhist Temple, located near Colombo in Sri Lanka, is renowned for its historical and religious 
    significance. 
    The Kelaniya Temple is believed to have been visited by Lord Buddha himself during his third and final visit to Sri Lanka, adding to 
    its sacredness in Buddhist tradition.
    The temple showcases traditional Sri Lankan Buddhist architecture with intricate carvings, paintings, and statues that depict scenes 
    from Buddhist teachings and legends. It is a popular pilgrimage site for Buddhists, who visit to pay homage, meditate, and participate 
    in religious ceremonies and rituals held throughout the year.
    The annual Duruthu Perahera (Kelaniya Temple Perahera) is a grand procession featuring traditional dancers, drummers, and elephants, 
    attracting devotees and tourists alike.
    The serene surroundings of the temple, nestled amidst lush greenery along the Kelani River, provide a peaceful environment for 
    contemplation and spiritual reflection.`,
  },
  {
    id: 8,
    destinationTitle: 'Kandy',
    image: kandyImg1,
    description: `Kandy, nestled in the hills of central Sri Lanka, is a city renowned for its rich cultural heritage and scenic beauty. 
    As the last capital of the ancient kings' era, it boasts a UNESCO World Heritage site - the Sacred City of Kandy - which includes the 
    Temple of the Tooth Relic (Sri Dalada Maligawa), a revered pilgrimage site housing a relic of the Buddha's tooth. Kandy is also famous 
    for its picturesque Kandy Lake, lush botanical gardens, traditional dance performances, and annual Esala Perahera festival, where 
    ornately adorned elephants parade through the streets in celebration of Buddhist traditions and Sri Lankan culture. This blend of 
    history, spirituality, and natural splendor makes Kandy a captivating destination for visitors seeking to explore Sri Lanka's 
    cultural heartland.`,

    subDest1Title: 'Dunuvila Lake',
    subDest1Image: kandyImg2,
    subDest1Description: `Dunuvila Lake, located in Sri Lanka's Kandy district, holds significance primarily in local folklore and 
    ecological importance. 
    According to local folklore, Dunuvila Lake is believed to be the site where a mythical battle took place between two giants, 
    resulting in the creation of the lake. This legend adds to its cultural allure and is passed down through generations.
    The lake serves as a crucial habitat for diverse flora and fauna, contributing to the region's biodiversity. The lake's surroundings 
    are dotted with ancient temples and historical sites, adding to its cultural and historical significance in the region.`,
  },
  {
    id: 9,
    destinationTitle: 'Dambulla',
    image: dambullaImg1,
    description: `Dambulla, situated in the central part of Sri Lanka, is renowned for its UNESCO World Heritage site, the Dambulla 
    Cave Temple Complex. This complex, also known as the Golden Temple of Dambulla, consists of five caves adorned with intricate 
    Buddhist murals and over 150 Buddha statues, including a 14-meter-long reclining Buddha. The site dates back to the 1st century 
    BCE and is one of the best-preserved cave temple complexes in Sri Lanka, attracting visitors from around the world for its cultural, 
    historical, and religious significance. Besides its archaeological wonders, Dambulla town offers a vibrant local market and scenic 
    landscapes that add to its appeal as a cultural destination.`,

    subDest1Title: 'Sigiriya Rock',
    subDest1Image: dambullaImg2,
    subDest1Description: `Sigiriya, located in the central Matale District of Sri Lanka, is an ancient rock fortress and UNESCO World 
    Heritage site. Rising 200 meters above the surrounding plains, Sigiriya is famous for its impressive rock plateau, adorned with 
    frescoes, a mirror wall with ancient graffiti, and the remnants of a palace built in the 5th century by King Kasyapa. It is 
    renowned not only for its historical significance but also for its stunning natural surroundings, making it a popular destination 
    for tourists interested in Sri Lanka's rich cultural heritage and architectural marvelsn.`,

    subDest2Title: 'Golden Temple of Dambulla',
    subDest2Image: dambullaImg3,
    subDest2Description: `The Golden Temple of Dambulla, also known as the Dambulla Cave Temple Complex, is a UNESCO World Heritage site 
    located in central Sri Lanka. It comprises five caves carved into a massive rock formation, housing over 150 stunning Buddha statues 
    and intricate murals depicting Buddhist teachings and mythology. Dating back to the 1st century BCE, the temple complex is one of the 
    oldest and best-preserved cave temple complexes in Sri Lanka, symbolizing the country's rich cultural and religious heritage. The site 
    is revered by Buddhists and attracts visitors worldwide for its historical significance, artistic treasures, and serene spiritual 
    ambiance set amidst scenic surroundings`,
  },
  {
    id: 10,
    destinationTitle: 'Anuradhapura',
    image: anuradhapuraImg1,
    description: `Anuradhapura, one of Sri Lanka's ancient capitals, is a UNESCO World Heritage site renowned for its well-preserved ruins 
    of an ancient Sinhalese civilization. Founded in the 4th century BC, it served as the island's center of Theravada Buddhism for many 
    centuries. The city is home to impressive stupas like Ruwanwelisaya and Jethawanaramaya, ancient monasteries, and the sacred Bodhi tree,
     a descendant of the tree under which Buddha attained enlightenment. Anuradhapura remains a significant pilgrimage site, attracting 
     visitors with its historical, religious, and architectural treasures.`,
  
    subDest1Title: 'Sri Maha Bodhi',
    subDest1Image: anuradhapuraImg3 ,
   
    subDest2Title: 'Ruwanweli Stupa',
    subDest2Image: anuradhapuraImg6,
   
    subDest3Title: 'Thuparama Stupa',
    subDest3Image: anuradhapuraImg7,

    subDest4Title: 'Brazen Palace',
    subDest4Image: anuradhapuraImg4,

    subDest5Title: 'Mirisaweti Stupa',
    subDest5Image: anuradhapuraImg2,

    subDest6Title: 'Abhayagiri Stupa',
    subDest6Image: anuradhapuraImg8,

    subDest7Title: 'Jetavanarama Stupa',
    subDest7Image: anuradhapuraImg9,

    subDest8Title: ' Lankaramaya Stupa',
    subDest8Image: anuradhapuraImg5,
   
  },
  {
    id: 11,
    destinationTitle: 'Matale',
    image: mathaleImg1,
    description: `Matale, located in the central region of Sri Lanka, is a vibrant town known for its lush landscapes and cultural heritage. 
    It is home to the famous Aluvihara Rock Temple, a historic cave monastery where the Buddhist scriptures, the Tripitaka, were first 
    written down on palm leaves in the 1st century BC. The temple features stunning rock carvings and ancient frescoes. Matale is also 
    renowned for its spice gardens, where visitors can explore and learn about the cultivation of Sri Lanka's rich array of spices, 
    including cinnamon, pepper, and cardamom. These gardens offer a sensory experience and insight into the island's spice trade heritage.`,

    subDest1Title: 'Alu Viharaya',
    subDest1Image: mathaleImg2,
    subDest1Description: `Matale Alu Viharaya, located in the Matale district of Sri Lanka, is an ancient Buddhist temple renowned for its 
    historical significance and rock cave architecture. It is believed to be the site where the Pali Canon, the sacred scriptures of 
    Theravada Buddhism, was first written down in the 1st century BCE. The temple features stunning frescoes and statues, making it an 
    important cultural and religious landmark in Sri Lanka's heritage.`,

    subDest2Title: 'National Spice Garden',
    subDest2Image: mathaleImg3,
    subDest2Description: `Matale Spice Garden, situated in the Matale district of Sri Lanka, is a popular attraction showcasing the island's 
    rich heritage of spices. Visitors can explore a variety of spices like cinnamon, cardamom, cloves, and pepper, while learning about 
    their cultivation, processing, and traditional uses in cooking and medicine. The garden offers guided tours, giving insight into the 
    spice trade that has played a vital role in Sri Lanka's history and culture.`,
  },
  {
    id: 12,
    destinationTitle: 'Hambantota',
    image: hambantotaImg1,
    description: `Hambantota, located in southern Sri Lanka, is a growing tourism destination known for its rich cultural heritage, natural 
    beauty, and wildlife. The region is home to attractions like the Yala National Park, famous for its leopard population, and the Bundala 
    National Park, a UNESCO biosphere reserve teeming with birdlife.Ridiyagama safari park and Hambanthota birds research center are most 
    eye-catching places. Hambantota also boasts pristine beaches, ancient temples, and historical sites. With its new international airport 
    and seaport, Hambantota is increasingly becoming a hub for tourism and economic development in Sri Lanka.`,

    subDest1Title: 'Kataragama Devalaya',
    subDest1Image: hambantotaImg2,
    subDest1Description: `Kataragama Devalaya, located in the sacred town of Kataragama in southern Sri Lanka, is not only a significant 
    religious site for Buddhists, Hindus, and indigenous Vedda people but also holds importance in the Ramayana epic. According to local 
    legends, this is where Lord Rama's brother, Lakshmana, was injured during the battle against Ravana. The temple complex is dedicated to 
    the deity Kataragama, revered by Hindus as Skanda-Murugan and associated with Lord Rama's devotion. The site attracts pilgrims who honor 
    its spiritual significance and its connection to the Ramayana, blending religious traditions and epic heritage in Sri Lanka.`,

    subDest2Title: 'Ridiyagama Safari Park',
    subDest2Image: hambantotaImg3,
    subDest2Description: `Ridiyagama Safari Park, located in Hambantota, Sri Lanka, is the country's first open-air safari park. Spanning 
    over 500 acres, the park offers visitors a unique wildlife experience, where animals roam freely in expansive, naturalistic enclosures. 
    The park is divided into different zones, including sections for herbivores, carnivores, and an African Lion Zone, allowing visitors to 
    observe a variety of animals, such as lions, elephants, zebras, and giraffes, in their natural habitats. Ridiyagama Safari Park is a 
    popular destination for nature lovers and families, providing an immersive and educational wildlife adventure.`,

    subDest3Title: 'Hambantota Birds Park',
    subDest3Image: hambantotaImg4,
    subDest3Description: `Hambantota Birds Park, located in the Hambantota District of southern Sri Lanka, is a captivating destination for 
    bird enthusiasts and nature lovers. The park is home to a diverse collection of bird species, including both native and exotic varieties, 
    housed in spacious aviaries and naturalistic environments. Visitors can observe a wide range of birds, from colourful parrots and 
    peacocks to majestic eagles and owls. The park also emphasizes conservation and education, offering insights into avian behaviour, 
    habitats, and the importance of protecting bird species. Hambantota Birds Park is a must-visit for those seeking a peaceful and 
    educational experience in Sri Lanka's wildlife.`,
  },
  // Add more destinations as needed
];

export default destination;
