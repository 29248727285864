import React from 'react';
import './whySitaRama.css';

const WhySitaRama = () => {
  return (
    <div className="containerwhy">
      <h1>What We Offer</h1>
      <div className='section'>
      <ol>
        <li><b>Guided Tours:</b> Our meticulously planned guided tours take you through the most significant sites of the Ramayana, 
        including kovils, caves, and ancient ruins. Each location is chosen for its historical and mythological relevance, providing you 
        with a comprehensive understanding of the epic..</li>
        <li><b>Cultural Experiences:</b> Immerse yourself in local traditions, festivals, and rituals that have been influenced by the 
        Ramayana. Participate in storytelling sessions, traditional performances, and culinary experiences that bring the epic to life</li>
        <li><b>Customized Itineraries:</b> We believe that every traveler is unique. Our customized itineraries are tailored to meet your 
        specific interests and needs, whether you're a solo adventurer, a family, or a group of friends.</li>
        <li><b>Join us on a journey that transcends time.</b> Discover the ancient paths walked by legendary heroes, experience the 
        vibrant culture and history of the regions, and create memories that will last a lifetime. </li>
      </ol>
      </div>
    </div>
  )
}

export default WhySitaRama;
