// src/components/TopBar/TopBar.js
import React from 'react';
import './Topbar.css';
import '../../App.css';
import { FaFacebook, FaInstagram, FaPhone, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter, FaTiktok } from "react-icons/fa6";
import videoBg from '../../assets/videos/nav-bg6.mp4';
import GoogleTranslate from '../GoogleTranslator/googleTranslator';

const TopBar = () => {
  return (
    <div className="top-bar">
      <div className='video-bg'>
        <video src={videoBg} autoPlay loop muted />
      </div>
      <div className="social-media-icons">
        <a href="https://www.facebook.com/profile.php?id=61561780684547&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://x.com/SitaramaTravals?t=9-UVrAFnHMBBbBEoHfBokw&s=09" target="_blank" rel="noopener noreferrer">
          <FaXTwitter />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://www.tiktok.com/@sitarama.travels" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
        </a>
        <a href="https://www.linkedin.com/in/sitarama-travels-1637a9317?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </div>
      <div className='topbar-right-side-content'>
        <div className='contact-links'>
          <a href="tel:+94744435434" className="contact-link">
            <FaPhone /> +(94) 744 435 434
          </a>
          <a href="https://www.google.com/maps/place/123+Main+St,+Anytown,+USA" target="_blank" rel="noopener noreferrer" className="contact-link">
            <FaMapMarkerAlt /> Colombo-05 | Cinnamon life | Ayodhya
          </a>
        </div>
        <GoogleTranslate /> {/* Add the GoogleTranslate component here */}
      </div>
    </div>
  );
};

export default TopBar;
