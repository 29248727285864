import React from 'react';
import './refundPolicy.css';
import backgroundVideo from '../../assets/videos/policyBG.mp4';

const refundPolicy = () => {
  return (
    <div className="refund-container">
      <div className="video-banner">
        <video className="video-banner__video" autoPlay muted loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-banner__overlay">
          <h2 className="refund-header">
            Refund Policy 
          </h2>
        </div>
      </div>
      <div className="refund-section">
      <p className="refund-paragraph">
      All arrangements are inclusive. Cancellation must be in writing and sent by you or your representative who made the booking on your 
      behalf. In event a group booking is cancelled by the tour leader (representative); each individual will be equally held liable for 
      cancellation costs. Cancellations are timed from when we contact the supplier on your behalf. A delay is normal outside normal 
      working hours.
        </p>
        <h3 className='header2'>1. Definitions and Scope </h3>
        <p className="refund-paragraph">
        •	<b>Cancellation:</b> Termination of a booking by the customer.
        </p>
        <p className="refund-paragraph">
        •	<b>Modification:</b>Modification: Changes made to an existing booking.
        </p>
        <p className="refund-paragraph">
        •	<b>Refund:</b> Return of payment to the customer under certain conditions.
        </p>
        <p className="refund-paragraph">
        •	This policy covers all travel packages and services provided by Sita Rama Travels and Tours Pvt Ltd.
        </p>
        <br></br>
        <p className="refund-paragraph">
        No refund in full or in part will be given for un-utilized services in the package(s) once you commence using the product or 
        service. In general, early check-out and no shows re not refunded.
        </p>
        <p className="refund-paragraph">
        <b>Our general refund policy is as follows;</b>
        </p>
        <p className="refund-paragraph">
        Number of days in advance cancellation notice received prior to commencement of tour or hotel stay
        </p>
        <h3 className='header2'>2. Cancellation Fees </h3>
        <ul className='listterms'>
        <li >•	Cancellations made more than 30 days before the departure date: No cancellation fee.</li>
        <li>•	Cancellations made 15-30 days before the departure date: 50% of the total booking amount. </li>
        <li>•	Cancellations made less than 15 days before the departure date: 100% of the total booking amount. </li>
        <li>•	No-shows: 100% of the total booking amount. </li>
        </ul>
        <h3 className='header2'>3. Refund Eligibility </h3>
        <ul className='listterms'>
        <li >•	Refunds are eligible for cancellations made according to the cancellation fee schedule.</li>
        <li>•	Non-refundable deposits and booking fees are not eligible for refunds. </li>
        <li>•	Specific services (e.g., flights, accommodation) may have separate refund policies. </li>
        </ul>
        <h3 className='header2'>4. Refund Process </h3>
        <ul className='listterms'>
        <li >•	Refund requests must include the booking reference number and reason for cancellation.</li>
        <li>•	Refunds will be processed within 14 business days of receiving the request. </li>
       </ul>
        <h3 className='header2'>5. Non-refundable Items</h3>
        <ul className='listterms'>
        <li >•	Booking fees and deposits are non-refundable.</li>
        <li>•	Certain promotional packages and special offers are non-refundable. These will be clearly marked at the time of booking. </li>
       </ul>
        <h3 className='header2'>6. Third-Party Providers</h3>
        <ul className='listterms'>
        <li >•	Services provided by third-party suppliers (e.g., hotels, airlines) are subject to their own cancellation and refund policies.</li>
        <li>•	Sita Rama Travels and Tours Pvt Ltd is not responsible for cancellations or refunds for services provided by third parties. </li>
       </ul>
        <h3 className='header2'>7. Force Majeure </h3>
        <ul className='listterms'>
        <li >•	In the event of force majeure (e.g., natural disasters, pandemics), cancellations and refunds will be handled on a case-by-case basis.</li>
        <li>•	Sita Rama Travels and Tours Pvt Ltd reserves the right to offer alternative arrangements or credit for future travel instead of refunds. </li>
       </ul>
        <h3 className='header2'>8. Travel Insurance  </h3>
        <ul className='listterms'>
        <li >•	Customers are strongly advised to purchase travel insurance to cover potential cancellations and unforeseen events.</li>
        <li>•	Information on recommended travel insurance providers is available on our website.</li>
       </ul>
       <h3 className='header2'>9. Communication Channels  </h3>
        <ul className='listterms'>
        <li >•	For cancellations and refund requests, customers can reach us via:</li>
        <ul className='list2'>
          <li>Email: info@sitaramatravels.com</li>
          <li>Phone: +94-117 760 111, +94 744 435 434</li>
        </ul>
       </ul>
       <h3 className='header2'>10. Documentation Requirements </h3>
        <ul className='listterms'>
        <li >•	Customers may be required to provide documentation such as medical certificates or proof of unforeseen events to process refund requests.</li>
       </ul>
       <h3 className='header2'>11. Customer Service </h3>
        <ul className='listterms'>
        <li >•	Our customer service team is available to assist with cancellations and refunds during business hours (Monday to Friday, 9 AM to 6 PM IST).</li>
        <li >•	Contact us at info@sitaramatravels.com or call +94-117 760 111, +94 744 435 434</li>
       </ul>
       <h3 className='header2'>12. Legal Compliance </h3>
        <ul className='listterms'>
        <li >•	This policy complies with local and international consumer protection laws.</li>
        <li >•	Customers have the right to seek legal advice if they believe their rights have been violated.</li>
       </ul>
       <h3 className='header2'>13. Policy Updates </h3>
        <ul className='listterms'>
        <li >•	Sita Rama Travels and Tours Pvt Ltd reserves the right to update or change this policy at any time.</li>
        <li >•	Customers will be notified of any changes via email and updates will be posted on our website.</li>
       </ul>
      </div>
    </div>
  )
}

export default refundPolicy
