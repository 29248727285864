import React from 'react';
import './whyChooseSitaRama.css';
import backgroundVideo from '../../assets/videos/wcu.mp4';

const WhyChooseSitaRama = () => {
  return (
    <div className="why-choose-container">
      <video className="video-banner__video" autoPlay muted loop>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="why-choose-content">
        <h1 className="whychooseheader">Why Would One Choose Sita Rama Travels & Tours?</h1><br></br>
        <p className="paragraph">Having grown to be a leading company in the travel industry, Sita Rama Travels & Tours is characterized by 
          its strong reputation and reliability. Our company is known as the best in the business with outstanding customer service as the 
          heart of our operations. Our team is comprised of trustworthy and experienced people who are well-versed in travel planning. 
          Whether you are carefully booking your hotel rooms and your flights, or personalizing your trip, we take care of every aspect of 
          the itinerary, making sure it is as accurate and as detailed as possible. A promise of providing the client with a travel 
          experience that completely suits him- or herself is ensuring our difference.</p>
          <p className="paragraph">Sita Rama Travels provides a broad variety of services which are designed to meet all peculiar travel 
            considerations. Whether you are looking for Ramayana trails to explore Sri Lanka's cultural richness with us or luxurious 
            getaways with our high-end itineraries, we have whatever a traveler wants. Our focus on offering personalized travel experiences 
            can tell you that we will not only meet but also exceed your expectations. We are equally pleased with our ability to offer 
            attractive price packages, among other offers, which not only help us to be in direct competition with other companies but, 
            more importantly, offer you the comfort and safety you need while traveling.</p>
            <p className="paragraph">Our excellence in work is the direct feedback from a large number of our happy clients who recognize 
              and appreciate it. We are most proud of the successful implementation of commitments, including unforgettable travel 
              experiences, which have been driving the company. Your decision to choose Sita Rama Travels & Tours will tell the whole world 
              your respect and also to us who in every single trip are looking for ways to make it stand out. Being trustworthy, dependable, 
              and having a real interest in travel are the attributes that we have established, as a result, this is the best option for your 
              next adventure.</p>
      </div>
    </div>
  )
}

export default WhyChooseSitaRama;
