import React from 'react';
import Refund from '../components/RefundPolicy/refundPolicy';

const RefundPolicyPage = () => {
  return (
    <div>
      <Refund />
    </div>
  )
}

export default RefundPolicyPage
