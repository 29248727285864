import React from 'react';
import './destinationBanner.css';

const ImageBanner = () => {
    return (
        <div className="image-banner">
            <div className="image-banner__overlay">
                {/* <h1 className="image-banner__text">Our Destinations</h1> */}
            </div>
        </div>
    );
};

export default ImageBanner;
