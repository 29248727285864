import React from 'react';
import Privacy from '../components/PrivacyPolicy/privacyPolicy';

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Privacy />
    </div>
  )
}

export default PrivacyPolicyPage
