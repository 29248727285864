import pic1 from '../assets/images/pic12.jpg';
import pic2 from '../assets/images/pic13.jpg';
import pic3 from '../assets/images/pic5.jpg';
import pic4 from '../assets/images/pic1.jpg';
import pic5 from '../assets/images/pic6.jpg';
import pic6 from '../assets/images/pic1.jpg';

const reviewData = [
    {
        img: pic1,
        name: 'Ravi Shashtri',
        message: '"Highly recommended From the first contact with them I noticed how professional they are and helpful in every single detail" '
    },
    {
        img: pic2,
        name: 'Thuan Shan',
        message: '"Enjoying the tour with the best driver ManjuAnuradhapura was with another driver, whom we would not"'
    },
    {
        img: pic3,
        name: 'Shame Nimasha',
        message: '"Perfect tour Excellent support from driver-guide Mr. Clement throughout the trip. Absolutely great! The tour in Sri"'
    },
    {
        img: pic4,
        name: 'Mark Antony ',
        message: '"A Memorable Vacation From the time we landed to the time we bid adieu to Sri Lanka, from 360Tours Lanka ensured we"'
    },
    {
        img: pic5,
        name: 'David Charles',
        message: '"Enjoying the tour with the best driver ManjuAnuradhapura was with another driver, whom we would not"'
    },
    {
        img: pic6,
        name: 'Kiruoanata deevaram',
        message: '"Perfect tour Excellent support from driver-guide Mr. Clement throughout the trip. Absolutely great! The tour in Srilanka"'
    },
];

export default reviewData;