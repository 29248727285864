// import React from 'react';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Navbar from './components/Navbar';
import Topbar from './components/Topbar';
import Home from './pages/Home';
import PackagePage from './pages/PackagePage';
import Packages from './pages/Packages';
import Footer2 from './components/Footer2/footer2';
import AboutUs from './pages/AboutUs';
import Destination from './pages/DestinationPage';
import DestinationDetails from './components/DestinationListDetails/destinationListDetails';
import TermsPolicyPage from './pages/TermsPolicyPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import RefundPolicy from './pages/RefundPolicyPage';
import WhySitaRama from './pages/WhySitaRamaPage';
import HotelSlider from './components/HotelSlider/hotelslider';
import HotelDetails from './components/HotelDetails/hotelDetails';
import CookiePolicy from './components/CookiePolicy/cookiePolicy';
import FAQ from './components/FAQ/faq';
import InsightfulTipsPage from './components/InsightfulTips/insightfulTips';
import ContactUsPage from './pages/ContactUsPage';
import PkgInquiryForm from './components/PkgInquiryForm/PkgInquiryForm';
import TripAdvisorPage from './pages/TripAdvisorPage';
import BeforeYourTrip from './pages/BeforYourTrip';
import TripAdvise from './pages/TripAdvisor';
import Bloglist from './pages/Blog';
import BlogPage from './pages/BlogPage';
import Gallery from './components/GalleryPage/galleryPage';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import HomeBgAudio from '../src/assets/audio/home-bg-audio.mp3';
import BookNow from './components/ReserveNowForm'
import Translator from './components/GoogleTranslator/googleTranslator';


function App() {
  
    const [play, setPlay] = useState(false);
  
    const togglePlay = () => {
      setPlay(prevPlay => !prevPlay);
    }
    // const [isHorizontal, setIsHorizontal] = useState(false);
  return (
    <>
      <Router>
        <Topbar />
        <Navbar />
        {/* video section imported */}
        <AudioPlayer src={HomeBgAudio} play={play} togglePlay={togglePlay}/>
        <Helmet>
        <title>Sita Rama Travel & Tours</title>
        <meta name="description" content="Explore Sri Lanka's Ramayana Trail, a journey of spiritual significance and cultural immersion. Follow the legendary footsteps of Lord Rama, Sita, and Hanuman through ancient temples, verdant forests, and majestic mountains. Let us guide you on this sacred pilgrimage, where history, mythology, and natural beauty converge in harmony." />
        <meta name="keywords" content="sita-rama, sita rama, sitarama, sitarama travels, sita rama travels, ramayan trail" />
      </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/package/:id" element={<PackagePage />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/destination" element={<Destination />} />
          <Route path="/destination/:id" element={<DestinationDetails />} />
          <Route path="/termspolicy" element={<TermsPolicyPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/insightfulTips"  element={<InsightfulTipsPage />} />
          <Route path="/whysitarama" element={<WhySitaRama />} />
          <Route path="/hotelslider" element={<HotelSlider />} />
          <Route path="/hotel/:id" element={<HotelDetails />} />
          <Route path="/contact-us-page" element={<ContactUsPage/>} />
          <Route path='/pkg-inquiry-form' element={<PkgInquiryForm/>}/>
          <Route path='/review' element={<TripAdvisorPage />} />
          <Route path='/beforeYourTrip' element={<BeforeYourTrip />} />
          <Route path='/tripAd' element={<TripAdvise />} />
          <Route path='/blogs' element={<Bloglist />} />
          <Route path="/blog/:id" element={<BlogPage />} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path='/reservenow' element={<BookNow/>}/>
          <Route path='/googleTranslator' element={<Translator/>}/>
        </Routes>
      </Router>
      <Footer2 />
    </>
  );
}

export default App;
