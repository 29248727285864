import React from 'react';
import './PkgBannerSection.css';

function PkgBannerSection({ packageDetail }) {
  return (
    <div className="pkg-banner-section">
      <div className="pkg-banner-item">
        <h2 className="pkg-title">Destinations in Your Yaatra!</h2>
        <div className="pkg-places">
          {packageDetail.placesBanner.map((banner, index) => (
            <div key={index} className="pkg-place-item">
              <img src={banner} alt={`Banner ${index + 1}`} className="pkg-place-image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PkgBannerSection;
