import React, { useState, useEffect } from 'react';
import '../../App.css';
import './VideoSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

function VideoSection() {
  const [isMuted, setIsMuted] = useState(true);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    // Load the YouTube IFrame API script
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Create a YouTube player - https://www.youtube.com/watch?v=11kpr-ezPdM - 
    window.onYouTubeIframeAPIReady = () => {
      const newPlayer = new window.YT.Player('youtube-player', {
        videoId: '11kpr-ezPdM', // Replace with your video ID
        playerVars: {
          autoplay: 1,
          loop: 1,
          playlist: '11kpr-ezPdM',
          rel: 0,
          controls: 0,
          showinfo: 0,
          modestbranding: 1,
        },
        events: {
          onReady: (event) => {
            event.target.mute();
            setPlayer(event.target);
          },
        },
      });
    };
  }, []);

  const toggleMute = () => {
    if (player) {
      if (isMuted) {
        player.unMute();
      } else {
        player.mute();
      }
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className='video-container'>
      <div id="youtube-player" className="youtube-video"></div>
      <button className="mute-button" onClick={toggleMute}>
        <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
      </button>
    </div>
  );
}

export default VideoSection;
