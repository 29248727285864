import React from 'react';
import { useParams } from 'react-router-dom';
import destinationData from '../../data/destination';
import './destinationListDetails.css';

const DestinationDetails = () => {
    const { id } = useParams();
    const destination = destinationData.find(dest => dest.id === parseInt(id));

    if (!destination) {
        return <div>Destination not found</div>;
    }

    return (
        <div className="destination-details">
            <h1>{destination.destinationTitle}</h1>
            <img src={destination.image} alt={destination.destinationTitle} />
            <p>{destination.description}</p>
            <div className="sub-destination">
                <h2>{destination.subDest1Title}</h2>
                <img src={destination.subDest1Image} alt={destination.subDest1Title} />
                <p>{destination.subDest1Description}</p>
                {destination.subDest2Title && (
                    <>
                        <h2>{destination.subDest2Title}</h2>
                        <img src={destination.subDest2Image} alt={destination.subDest2Title} />
                        <p>{destination.subDest2Description}</p>
                    </>
                )}
                {destination.subDest3Title && (
                    <>
                        <h2>{destination.subDest3Title}</h2>
                        <img src={destination.subDest3Image} alt={destination.subDest3Title} />
                        <p>{destination.subDest3Description}</p>
                    </>
                )}
                {destination.subDest4Title && (
                    <>
                        <h2>{destination.subDest4Title}</h2>
                        <img src={destination.subDest4Image} alt={destination.subDest4Title} />
                        <p>{destination.subDest4Description}</p>
                    </>
                )}
                 {destination.subDest5Title && (
                    <>
                        <h2>{destination.subDest5Title}</h2>
                        <img src={destination.subDest5Image} alt={destination.subDest4Title} />
                        <p>{destination.subDest5Description}</p>
                    </>
                )}
                {destination.subDest6Title && (
                    <>
                        <h2>{destination.subDest65Title}</h2>
                        <img src={destination.subDest6Image} alt={destination.subDest4Title} />
                        <p>{destination.subDest6Description}</p>
                    </>
                )}
                {destination.subDest7Title && (
                    <>
                        <h2>{destination.subDest7Title}</h2>
                        <img src={destination.subDest7Image} alt={destination.subDest4Title} />
                        <p>{destination.subDest7Description}</p>
                    </>
                )}
                {destination.subDest8Title && (
                    <>
                        <h2>{destination.subDest8Title}</h2>
                        <img src={destination.subDest8Image} alt={destination.subDest4Title} />
                        <p>{destination.subDest8Description}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default DestinationDetails;
