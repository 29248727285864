import React from 'react';
import BeforeYT from '../components/Travaltips/Travaltips';

const BeforYourTrip = () => {
  return (
    <div>
      < BeforeYT />
    </div>
  )
}

export default BeforYourTrip
