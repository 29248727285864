import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contact.css';
import { FaFacebook, FaTwitter, FaInstagram, FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import con2 from '../../assets/images/sri lanka map.jpeg';
import con3 from '../../assets/images/india map.jpeg';

const Contact = ({ submitForm, layout }) => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    number: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.fname) tempErrors.fname = 'First Name is Required';
    if (!formData.lname) tempErrors.lname = 'Last Name is Required';
    if (!formData.email) tempErrors.email = 'Email is Required';
    if (!formData.message) tempErrors.message = 'Message is Required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await emailjs.send('service_qpeny7r', 'template_xm4yuml', formData, 'c1S_nasteVdWQakKn');
        if (response.status === 200) {
          alert('Message Sent Successfully');
          setShowPopup(true);
          setFormData({
            fname: '',
            lname: '',
            email: '',
            number: '',
            message: '',
          });
        } else {
          alert('Failed to send message');
        }
      } catch (error) {
        console.error('An error occurred:', error);
        alert('An error occurred');
      }
    }
  };

  return (
    <div className='con2'>
      <h4>Ready to Get our best Services! Feel free to contact with us</h4>
      <h5> (+94) 744 435 434</h5>
      <h6>24×7 Customer Support</h6>
      <section id='section-wrapper'>
        <div className='box-wrapper'>
          <div className='info-wrap'>
            <h2 className='info-title'>Contact Information</h2>
            <h3 className='info-sub-title'>Fill Up the form and our team will be get back you within 24 hours</h3>
            <ul className='info-details'>
              <li>
                <i className='info-detail-icon'><FaPhone /></i>
                <span></span><a href="tel:+0117022932">(+94) 744 435 434</a>
              </li>
              <li>
                <i className='info-detail-icon'><FaPhone /></i>
                <span></span><a href="tel:+0117022932">(+94) 117 760 111</a>
              </li>
              <li>
                <i className='info-detail-icon'><MdOutlineEmail /></i>
                <span></span><a href="mailto:info@sitaramatravels.com">info@sitaramatravels.com</a>
              </li>
              <li>
                <i className='info-detail-icon'><CgWebsite /></i>
                <span></span><a href="https://sitaramatravels.com">www.sitaramatravels.com</a>
              </li>
            </ul>
            <ul className='social-icons'>
              <li><a href=" "><i><FaFacebook /></i></a></li>
              <li><a href=" "><i><FaTwitter /></i></a></li>
              <li><a href=" "><i><FaInstagram /></i></a></li>
            </ul>
          </div>

          <div className='form-wrap'>
            <form onSubmit={handleSubmit} method='post'>
              <h2 className='form-title'>Send us a message</h2>
              <div className='form-fields'>
                <div className='form-group'>
                  <input type="text" name="fname" value={formData.fname} onChange={handleChange} className='fname' placeholder='First Name' />
                  {errors.fname && <span>{errors.fname}</span>}
                </div>
                <div className='form-group'>
                  <input type="text" name="lname" value={formData.lname} onChange={handleChange} className='lname' placeholder='Last Name' />
                  {errors.lname && <span>{errors.lname}</span>}
                </div>
                <div className='form-group'>
                  <input type="email" name="email" value={formData.email} onChange={handleChange} className='email' placeholder='Email' />
                  {errors.email && <span>{errors.email}</span>}
                </div>
                <div className='form-group'>
                  <input type="number" name="number" value={formData.number} onChange={handleChange} className='phone' placeholder='Phone' />
                </div>
                <div className='form-group'>
                  <textarea name="message" value={formData.message} onChange={handleChange} id="" placeholder='Write your message'></textarea>
                </div>
              </div>
              <input type="submit" value="Send message" className='submit-button' />
            </form>
            {showPopup && (
              <div className="popup">
                <p>Message submitted successfully!</p>
                <button onClick={() => setShowPopup(false)}>Close</button>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className='ccontainer'>
      

      <div className='cbox'>
        
        <h3><u>Head office</u></h3>
        <img src={con2} alt="" className='con21'/>
        <p>Sitarama Travals & Tours Pvt.Ltd</p>
        <p>No 41/5 D.S Fonseka Road, </p>
        <p>Colombo-05, Sri Lanka</p>
        <p>+(94) 744 435 434 | +(94) 117 760 111 </p>
        
      </div>

      <div className='cbox'>
        
      <h3><u>Sales office</u></h3>
      <img src={con3} alt="" className='con21'/>
        <p>Sitarama Travals & Tours Pvt.Ltd</p>
        <p>Sai Nagar, Ayodhya Uttar Paradesh,</p>
        <p>224123, India.</p>
        <p>+(94) 744 435 434 | +(94) 117 760 111</p>
      </div>

      <div className='cbox'>
        
      <h3><u>Coperative office</u></h3>
      <img src={con2} alt="" className='con21'/>
        <p>Sitarama Travals & Tours Pvt.Ltd</p>
        <p>Level 27, Cinnamon Life, "The Offices",
        130 Glennie St,</p>
        <p>Colombo-02, Sri Lanka.</p>
        <p>+(94) 744 435 434 | +(94) 117 760 111</p>
      </div>

    </div>
    <div className="map-container">
  <iframe 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28492.60053878203!2d82.17856823110797!3d26.78981201962918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a07937e6d2823%3A0x5fc8f683b17f222b!2sAyodhya%2C%20Uttar%20Pradesh%2C%20India!5e0!3m2!1sen!2slk!4v1720687043000!5m2!1sen!2slk" 
    className="map1"
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  />
</div>
    </div>
  );
}

export default Contact;
