import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ReserveNowForm.css';

const ReserveNowForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    package: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS configuration
    emailjs.send('service_2swb3bq', 'template_m0okaq8', formData, 'c1S_nasteVdWQakKn')
      .then((response) => {
        setFormStatus('Success! Your reservation request has been sent.');
        setFormData({
          name: '',
          email: '',
          contact: '',
          package: '',
          message: ''
        });
      }, (error) => {
        setFormStatus('Failed to send reservation request. Please try again later.');
      });
  };

  return (
    <div className='reserve-now-form-container'>
         <form className="reserve-now-form" onSubmit={handleSubmit}>
      <h2>Reserve Your Package</h2>
      <input
        type="text"
        name="name"
        placeholder="Your Name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Your Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="contact"
        placeholder="Your Contact Number"
        value={formData.contact}
        onChange={handleChange}
        required
      />
      <select
        name="package"
        value={formData.package}
        onChange={handleChange}
        required
      >
        <option value="">Select Package</option>
        <option value="Package 1">Package 1</option>
        <option value="Package 2">Package 2</option>
        <option value="Package 3">Package 3</option>
        <option value="Package 4">Package 4</option>
      </select>
      <textarea
        name="message"
        placeholder="Additional Message (optional)"
        value={formData.message}
        onChange={handleChange}
      ></textarea>
      <button type="submit">Reserve Now</button>
      {formStatus && <p className="form-status">{formStatus}</p>}
    </form>
    </div>
  );
};

export default ReserveNowForm;
