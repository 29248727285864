import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './PkgSlider.css';
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import pkg_slider_img_1 from '../../assets/images/PkgSliderImg/pkg_slider_1.jpg';
import pkg_slider_img_2 from '../../assets/images/PkgSliderImg/pkg_slider_2.jpg';
import pkg_slider_img_3 from '../../assets/images/PkgSliderImg/pkg_slider_3.jpg';
import pkg_slider_img_4 from '../../assets/images/PkgSliderImg/pkg_slider_4.jpg';
import pkg_slider_img_5 from '../../assets/images/PkgSliderImg/pkg_slider_5.jpg';
import pkg_slider_img_6 from '../../assets/images/PkgSliderImg/pkg_slider_6.jpg';
import pkg_slider_img_7 from '../../assets/images/PkgSliderImg/pkg_slider_7.jpg';
import pkg_slider_img_8 from '../../assets/images/PkgSliderImg/pkg_slider_8.jpg';

export default function PkgSlider() {
  return (
    <div className='pkg-slider-container'>
        <h1 className='pkg-slider-heading'>Destinations You will Explore in Your Yaatra</h1>
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={3}
            coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
                slideShadows: true,
            }}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className='swiper_container'
        >
            <SwiperSlide>
                <img src={pkg_slider_img_1} alt='slide_image'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={pkg_slider_img_2} alt='slide_image'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={pkg_slider_img_3} alt='slide_image'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={pkg_slider_img_4} alt='slide_image'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={pkg_slider_img_5} alt='slide_image'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={pkg_slider_img_6} alt='slide_image'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={pkg_slider_img_7} alt='slide_image'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={pkg_slider_img_8} alt='slide_image'/>
            </SwiperSlide>

            
            <div className='pkg-slider-controler'>
                <div className='swiper-button-prev slider-arrow'>
                    <FaArrowCircleLeft />
                </div>
                <div className='swiper-button-next slider-arrow'>
                    <FaArrowCircleRight />
                </div>
                <div className='swiper-pagination'></div>
            </div>
        </Swiper>
    </div>
  )
}
