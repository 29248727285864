import React from 'react';
import './faq.css';
import backgroundVideo from '../../assets/videos/policyBG.mp4';

const Faq = () => {
  const faqs = [
    {
      question: 'Are the hotels wheel-chair accessible?',
      answer: 'Yes, there are some hotels and the teams at the properties are ever ready to assist patrons in any way they may require.'
    },
    {
      question: 'Do the hotels provide accessible rooms?',
      answer: 'Yes, most of the hotels have accessible rooms.'
    },
    {
      question: 'What hospitals should I go when needed?',
      answer: 'Every main town / city throughout the country would have a hospital or medical clinique available. Government Hospitals would provide free medical service whilst charges are applicable for Private Hospitals.'
    },
    {
      question: 'Can I get access to Ayurvedic Medicine?',
      answer: 'You can experience Ayurvedic treatments at the many spas as well as Ayurvedic hospitals. Special packages and Ayurvedic holiday getaways are also offered by most hotels in Sri Lanka.'
    }
  ];

  return (
    <div className="faq-container">
       <div className="video-banner">
        <video className="video-banner__video" autoPlay muted loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-banner__overlay">
          <h2 className="privacy-header">
           FAQ 
          </h2>
        </div>
      </div>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <h3 className="faq-question">{faq.question}</h3>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
