import React from 'react';
import './chilaw.css';
import Cimg1 from '../../assets/images/Chilaw/DSC_5459.JPG';
import Cimg2 from '../../assets/images/Chilaw/DSC_5461.JPG';
import Cimg3 from '../../assets/images/Chilaw/DSC_5465.JPG';
import Cimg4 from '../../assets/images/Chilaw/DSC_5468.JPG';
import Cimg5 from '../../assets/images/Chilaw/DSC_5471.JPG';
import Cimg6 from '../../assets/images/Chilaw/DSC_5474.JPG';
import Cimg7 from '../../assets/images/Chilaw/DSC_5475.JPG';
import Cimg8 from '../../assets/images/Chilaw/DSC_5476.JPG';
import Cimg9 from '../../assets/images/Chilaw/DSC_5477.JPG';
import Cimg10 from '../../assets/images/Chilaw/DSC_5483.JPG';
import Cvideo1 from '../../assets/images/Chilaw/DSC_5463.MOV';
import Cvideo2 from '../../assets/images/Chilaw/DSC_5480.MOV';

const chilaw = () => {
    const images = [
        { src: Cimg1, alt: '' },
        { src: Cimg2, alt: '' },
        { src: Cimg3, alt: '' },
        { src: Cimg4, alt: '' },
        { src: Cimg5, alt: '' },
        { src: Cimg6, alt: '' },
        { src: Cimg7, alt: '' },
        { src: Cimg8, alt: '' },
        { src: Cimg9, alt: '' },
        { src: Cimg10, alt: '' },
      ];
    
      const videos = [
        { src: Cvideo1, alt: '' },
        { src: Cvideo2, alt: '' },
      ];
    
      return (
        <div className="chilaw-gallery-container">
          <h1 className="chilaw-gallery-heading">Chilaw Gallery</h1>
          
          <div className="image-gallery">
            {images.map((image, index) => (
              <img 
                key={index} 
                src={image.src} 
                alt={image.alt} 
                className="chilaw-gallery-image" 
              />
            ))}
          </div>
    
          <div className="video-gallery">
            {videos.map((video, index) => (
              <video key={index} controls className="chilaw-gallery-video">
                <source src={video.src} type="video/mp4" />
                {video.alt}  
              </video>
            ))}
          </div>
        </div>
      );
    };

export default chilaw
