import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './tripAdvisor.css'
import pic1 from '../../assets/images/pic1.jpg';
import pic2 from '../../assets/images/pic2.jpg';
import pic3 from '../../assets/images/pic3.jpg';
import pic4 from '../../assets/images/pic5.jpg';
import pic5 from '../../assets/images/pic6.jpg';
import pic6 from '../../assets/images/pic1.jpg';
import pic7 from '../../assets/images/pic20.png';
import pic8 from'../../assets/images/img21.png';
import pic9 from '../../assets/images/pic12.jpg';
import pic10 from '../../assets/images/pic13.jpg';
import pic11 from '../../assets/images/pic5.jpg';
import pic12 from '../../assets/images/pic1.jpg';

const review1 = [
    {
        img: pic1,
        name: 'Ravi Shashtri',
        address: '"Highly recommended From the first contact with them I noticed how professional they are and helpful in every single detail" '
    },
    {
        img: pic2,
        name: 'Thuan Shan',
        address: '"Enjoying the tour with the best driver ManjuAnuradhapura was with another driver, whom we would not"'
    },
    {
        img: pic3,
        name: 'Shame Nimasha',
        address: '"Perfect tour Excellent support from driver-guide Mr. Clement throughout the trip. Absolutely great! The tour in Sri"'
    },
    {
        img: pic4,
        name: 'Mark Antony ',
        address: '"A Memorable Vacation From the time we landed to the time we bid adieu to Sri Lanka, from 360Tours Lanka ensured we"'
    },
    {
        img: pic5,
        name: 'David Charles',
        address: '"Enjoying the tour with the best driver ManjuAnuradhapura was with another driver, whom we would not"'
    },
    {
        img: pic6,
        name: 'Kiruoanata deevaram',
        address: '"Perfect tour Excellent support from driver-guide Mr. Clement throughout the trip. Absolutely great! The tour in Srilanka"'
    },
];

const review2 = [
    {
        img: pic9,
        name: 'Ravi Shashtri',
        address: '"Highly recommended From the first contact with them I noticed how professional they are and helpful in every single detail" '
    },
    {
        img: pic10,
        name: 'Thuan Shan',
        address: '"Enjoying the tour with the best driver ManjuAnuradhapura was with another driver, whom we would not"'
    },
    {
        img: pic11,
        name: 'Shame Nimasha',
        address: '"Perfect tour Excellent support from driver-guide Mr. Clement throughout the trip. Absolutely great! The tour in Sri"'
    },
    {
        img: pic12,
        name: 'Mark Antony ',
        address: '"A Memorable Vacation From the time we landed to the time we bid adieu to Sri Lanka, from 360Tours Lanka ensured we"'
    },
    {
        img: pic5,
        name: 'David Charles',
        address: '"Enjoying the tour with the best driver ManjuAnuradhapura was with another driver, whom we would not"'
    },
    {
        img: pic6,
        name: 'Kiruoanata deevaram',
        address: '"Perfect tour Excellent support from driver-guide Mr. Clement throughout the trip. Absolutely great! The tour in Srilanka"'
    },
];

const tripAdvisor = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,            
        autoplaySpeed: 2000,       
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

  return (
    <div className="slider-container2 custom-slick-slider">
            <div className='sectiontitle2'>
                <h1 className='clienth2' data-text="Words of Delight from Our Valued Travelers">
                    WORDS OF DELIGHT FROM OUR VALUED TRAVELERS
                </h1> 
                <h2 className='reviewp'>
                    Discover the experiences of our satisfied clients as they share their memorable <br />
                    journeys with sitarama travels. Read their heartfelt testimonials and get <br />
                    inspired to embark on your own unforgettable tour in Sri Lanka.
                </h2>
            </div>
            <img src={pic7} className='award2' alt="" />
            <Slider {...settings}>
                {review1.map((review1, index) => (
                    <div key={index} className="reviewslide2">
                        <div className="circle2">
                            <img src={review1.img} alt={review1.name} className="review-image2" />
                        </div>
                        <div className="info2">
                            <h3>{review1.name}</h3>
                            <p>{review1.address}</p>
                        </div>
                    </div>
                ))}
            </Slider>
            <img src={pic8} className='award' alt="" />
            <Slider {...settings}>
                {review2.map((review2, index) => (
                    <div key={index} className="reviewslide2">
                        <div className="circle2">
                            <img src={review2.img} alt={review2.name} className="review-image2" />
                        </div>
                        <div className="info2">
                            <h3>{review2.name}</h3>
                            <p>{review2.address}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
  );
}

export default tripAdvisor
