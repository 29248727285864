import React from 'react';
import Slider from 'react-slick';
import reviewData from '../../data/reviewData';
import './reviewSlider.css';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

// Custom Next Arrow Component
const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <FaArrowAltCircleRight
            className={className}
            style={{ ...style, display: 'block', color: '#50160f', fontSize: '30px', right: '-25px' }}
            onClick={onClick}
        />
    );
};

// Custom Prev Arrow Component
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <FaArrowAltCircleLeft
            className={className}
            style={{ ...style, display: 'block', color: '#50160f', fontSize: '30px', left: '-25px' }}
            onClick={onClick}
        />
    );
};


const ReviewSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        // nextArrow: <NextArrow />,
        // prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="review-slider">
            <div className='sectiontitle2'>
                <h1 className='clienth2' data-text="Words of Delight from Our Valued Travelers">
                    WORDS OF DELIGHT FROM OUR VALUED TRAVELERS
                </h1> 
                <h2 className='reviewp'>
                    Discover the experiences of our satisfied clients as they share their memorable <br />
                    journeys with sitarama travels. Read their heartfelt testimonials and get <br />
                    inspired to embark on your own unforgettable tour in Sri Lanka.
                </h2>
            </div>
            <Slider {...settings}>
                {reviewData.map((review, index) => (
                    <div key={index} className="review-card">
                        <img src={review.img} alt={review.name} className="review-img" />
                        <div className="review-content">
                            <h3 className="review-name">{review.name}</h3>
                            <p className="review-message">{review.message}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ReviewSlider;
