import React from 'react';
import AboutusBanner from '../components/AboutusBanner/aboutusbanner';
import VisionMission from '../components/VisionMission/visionmission';
import WhatSetUsApart from '../components/WhatSetUsApart/whatsetus';
import OurService from '../components/OurService/ourservice';
import WhySitaRama from '../components/WhySitaRama/whySitaRama';
import AboutUsBannerSection from '../components/AboutUsBannerSection/AboutUsBannerSection';


const AboutUs = () => {
  return (
    <>
      <AboutUsBannerSection/>
      <AboutusBanner />
      <VisionMission />
      <OurService />
      <WhatSetUsApart />
      <WhySitaRama />
    </>
  )
}

export default AboutUs
