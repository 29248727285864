import React from 'react';
import '../../App.css';
import './Description2.css';
import descripBgVideo from '../../assets/videos/descripBgVideo.mp4';


function Description2() {
  return (
    <div className='description2-container'>
         <div className='descrip-Bg-Video'>
          <video src={descripBgVideo} autoPlay loop muted />
      </div>
        <div className='description2'>
          <h1 className='description2-h1'>WHERE ANCIENT WONDERS MEET BREATHTAKING BEAUTY</h1>
          <p className='description2-text'>
            Explore Sri Lanka's Ramayana Trail, a journey of spiritual significance and cultural immersion. 
            Follow the legendary footsteps of Lord Rama, Sita, and Hanuman through ancient temples, 
            verdant forests, and majestic mountains. Let us guide you on this sacred pilgrimage, where history, 
            mythology, and natural beauty converge in harmony.
            </p><br></br>
            <p className='description2-text'>
            It's a spiritual journey that bridges ancient history with the modern world. 
            Endorsed by a key figure in the Hindu religious community, this initiative promises 
            to be a cornerstone for spiritual tourism, offering deep, meaningful experiences to 
            devotees and enhancing Sri Lanka's tourism industry. 
            Through this project, we aim to reignite the spiritual connection with the Ramayana Trail, 
            making it a beacon for devotees and pilgrims from around the globe.
            </p>
        </div>

        <div className='description-img'>
             {/* <img src={descrip} alt="Sita Rama" className="descrip-img" /> */}
        </div>
      
    </div>
  )
}

export default Description2
