import React from 'react';
import './whatsetus.css';
import { MdApproval, MdOutlineSentimentSatisfied, MdCenterFocusStrong, MdSafetyCheck } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";
import { RiGuideFill } from "react-icons/ri";
import { FaUserFriends, FaCar } from "react-icons/fa";
import { GiDetour } from "react-icons/gi";
import { AiFillInsurance } from "react-icons/ai";
import { DiResponsive } from "react-icons/di";
import { BsTransparency } from "react-icons/bs";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import backgroundvideo from '../../assets/videos/BG2.mp4';

const WhatSetsUs = () => {
  return (
    <div className="sets-us-apart-container">
      <video className="WSbackground-video" autoPlay loop muted>
        <source src={backgroundvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="sets-us-apart">
        <h2>Our Value & Commitment</h2>
        <div className="content">
          <div className="column">
            <ul>
              <li>
                <MdApproval className="icon" />
                <h3>Government approved tour operator</h3>
              </li>
              <li>
                <MdOutlineSentimentSatisfied className="icon" />
                <h3>Customer satisfaction</h3>
              </li>
              <li>
                <FaUserFriends className="icon" />
                <h3>Friendly and flexible</h3>
              </li>
              <li>
                <GiDetour className="icon" />
                <h3>Customize tour packages and set tours</h3>
              </li>
              <li>
                <MdSafetyCheck className="icon" />
                <h3>Safety</h3>
              </li>
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>
                <FaCar className="icon" />
                <h3>Wide range of vehicles</h3>
              </li>
              <li>
                <DiResponsive className="icon" />
                <h3>Responsiveness</h3>
              </li>
              <li>
                <AiFillInsurance className="icon" />
                <h3>Insured vehicles and travel insurance for travellers</h3>
              </li>
              <li>
                <GrUserExpert className="icon" />
                <h3>Expertise and experience</h3>
              </li>
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>
                <RiGuideFill className="icon" />
                <h3>Professional guides, chauffeurs and staff</h3>
              </li>
              <li>
                <BsTransparency className="icon" />
                <h3>Transparency</h3>
              </li>
              <li>
                <VscWorkspaceTrusted className="icon" />
                <h3>Trusted Service</h3>
              </li>
              <li>
                <MdCenterFocusStrong className="icon" />
                <h3>Customer Focussed</h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatSetsUs;
