import React, { useState } from 'react';
// import axios from 'axios';
import emailjs from 'emailjs-com';
import './PkgInquiryForm.css';

const PkgInquiryForm = ({ submitForm, layout }) => {
  const [formData, setFormData] = useState({
    name: '',
    package: '',
    contact: '',
    email: '',
    description: '',
  });

  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = 'Name is required';
    if (!formData.package) tempErrors.package = 'Package is required';
    if (!formData.contact) tempErrors.contact = 'Contact is required';
    if (!formData.email) tempErrors.email = 'Email is required';
    if (!formData.description) tempErrors.description = 'Description is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await emailjs.send('service_qpeny7r', 'template_xm4yuml', formData, 'c1S_nasteVdWQakKn');
        if (response.status === 200) {
          alert('Inquiry sent successfully');
          setShowPopup(true);
          setFormData({
            name: '',
            package: '',
            contact: '',
            email: '',
            description: '',
          });
        } else {
          alert('Failed to send inquiry');
        }
      } catch (error) {
        console.error('An error occurred:', error);
        alert('An error occurred');
      }
    }
  };

  return (
    <div className='pkg-form-container'>
      <div className='pkg-form-heading'>
        <h1>Inquiry form</h1>
      </div>
      <div className='form-container'>
        <form onSubmit={handleSubmit} className={layout === 'horizontal' ? 'horizontal-form' : 'vertical-form'}>
          <div>
            <label>Name</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} />
            {errors.name && <span>{errors.name}</span>}
          </div>
          <div>
            <label>Package</label>
            <select name="package" value={formData.package} onChange={handleChange}>
              <option value="">Select a package</option>
              <option value="Package 1">4 Nights/5 Days Tour A</option>
              <option value="Package 2">6 Nights/7 Days Tour B</option>
              <option value="Package 3">6 Nights/7 Days Tour C</option>
              <option value="Package 4">8 Nights/9 Days Tour D</option>
              <option value="Other">Other</option>
            </select>
            {errors.package && <span>{errors.package}</span>}
          </div>
          <div>
            <label>Contact</label>
            <input type="text" name="contact" value={formData.contact} onChange={handleChange} />
            {errors.contact && <span>{errors.contact}</span>}
          </div>
          <div>
            <label>Email</label>
            <input type="text" name="email" value={formData.email} onChange={handleChange} />
            {errors.email && <span>{errors.email}</span>}
          </div>
          <div>
            <label>Description</label>
            <textarea name="description" value={formData.description} onChange={handleChange}></textarea>
            {errors.description && <span>{errors.description}</span>}
          </div>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
        <div className='pkg-inq-terms'>
          <p>* By submitting this for form, you agreeing to the Term & Conditions and Privacy Policy of Sita Rama Travel & Tours (Pvt) Ltd. *</p>
        </div>
        {showPopup && (
          <div className="popup">
            <p>Form submitted successfully!</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PkgInquiryForm;
