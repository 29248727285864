import React from 'react';
import './privacyPolicy.css';
import backgroundVideo from '../../assets/videos/policyBG.mp4';

const privacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div className="video-banner">
        <video className="video-banner__video" autoPlay muted loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-banner__overlay">
          <h2 className="privacy-header">
            Privacy Policy 
          </h2>
        </div>
      </div>
      <div className="privacy-section">
        <h3 className='header2'>1. Introduction </h3>
        <p className="privacy-paragraph">
        At Sita Rama Travels and Tours Pvt Ltd, we are committed to protecting your privacy. This policy explains how we collect, use, 
        and protect your personal information.
        </p>
        <h3 className='header2'>2. Information We Collect  </h3>
        <ul className='listterms'>
        <li >2.1. We may collect personal information such as your name, address, email address, phone number, and payment details.</li>
        <li>2.2. We may also collect information about your travel preferences, dietary requirements, and health conditions relevant to 
          your travel arrangements. </li>
        </ul>
        <h3 className='header2'>3. How We Use Your Information </h3>
        <p className="privacy-paragraph">
        3.1. To process your bookings and provide you with the requested travel services.
        </p>
        <p className="privacy-paragraph">
        3.2. To communicate with you about your bookings and provide customer support.
        </p>
        <p className="privacy-paragraph">
        3.3. To send you promotional materials and special offers (you can opt-out at any time).
        </p>
        <h3 className='header2'>4. Information Sharing </h3>
        <p className="privacy-paragraph">
        4.1. We may share your information with third-party service providers, such as hotels and transportation companies, 
        to facilitate your travel arrangements.
        </p>
        <p className="privacy-paragraph">
        4.2. We will not sell, rent, or trade your personal information to third parties for marketing purposes.
        </p>
        <h3 className='header2'>5. Data Security </h3>
        <p className="privacy-paragraph">
        5.1. We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, 
        disclosure, alteration, or destruction.
        </p>
        <h3 className='header2'>6. Your Rights</h3>
        <p className="privacy-paragraph">
        6.1. You have the right to access, correct, or delete your personal information held by us.
        </p>
        <p className="privacy-paragraph">
        6.2. You can withdraw your consent to our use of your personal information at any time.
        </p>
        <h3 className='header2'>7. Changes to This Policy </h3>
        <p className="privacy-paragraph">
        7.1. We may update this privacy policy from time to time. Any changes will be posted on our website, and the revised policy 
        will be effective immediately.
        </p>
        <h3 className='header2'>8. Contact Us  </h3>
        <p className="privacy-paragraph">
        If you have any questions or concerns about our privacy policy, please contact us at:
        </p>
        <p className="privacy-paragraph">
        ●	Email: info@sitaramatravels.com
        </p>
        <p className="privacy-paragraph">
        ●	Phone: +94-117 760 111, +94 744 435 434
        </p>
        <br></br>
        <h3 className='header2'>SOFTWARE AND COPYRIGHT  </h3>
        <p className="privacy-paragraph">
        All contents of this website are copyright of ©sita rama Travels retains all rights including, but not limited to trademark rights, 
        copyright, and patent rights with respect to all software and underlying information or material available through this website.
        </p>
        <p className="privacy-paragraph">
        You are not to download, copy, or otherwise export or re-export any software or underlying information or material available through
        this website, except to the extent that such downloading or copying occurs in the course of using this website in accordance with 
        our written instructions or otherwise as expressly permitted in this agreement.
        </p>
        <h3 className='header2'>COPYRIGHT AND RESTRICTIONS ON USE  </h3>
        <p className="privacy-paragraph">
        All trademarks, copyright, and other intellectual property rights in materials on this website, as well as the organization and 
        layout of this website together with the underlying software, belong to Sita Rama Travels.
        </p>
      </div>
    </div>
  )
    
}

export default privacyPolicy
