import React from 'react';
import './trincomalee.css';
import Timg1 from '../../assets/images/Trincomalee/DSC_5553.JPG';
import Timg2 from '../../assets/images/Trincomalee/DSC_5554.JPG';
import Timg3 from '../../assets/images/Trincomalee/DSC_5555.JPG';
import Timg4 from '../../assets/images/Trincomalee/DSC_5566.JPG';
import Timg5 from '../../assets/images/Trincomalee/DSC_5568.JPG';
import Timg6 from '../../assets/images/Trincomalee/DSC_5571.JPG';
import Timg7 from '../../assets/images/Trincomalee/DSC_5573.JPG';
import Timg8 from '../../assets/images/Trincomalee/DSC_5575.JPG';
import Timg9 from '../../assets/images/Trincomalee/DSC_5579.JPG';
// import Tvideo1 from '../../assets/images/Trincomalee/DSC_5561.MOV';
import Tvideo2 from '../../assets/images/Trincomalee/DSC_5562.MOV';
import Tvideo3 from '../../assets/images/Trincomalee/DSC_5565.MOV';

const trincomalee = () => {
    const images = [
        { src: Timg1, alt: '' },
        { src: Timg2, alt: '' },
        { src: Timg3, alt: '' },
        { src: Timg4, alt: '' },
        { src: Timg5, alt: '' },
        { src: Timg6, alt: '' },
        { src: Timg7, alt: '' },
        { src: Timg8, alt: '' },
        { src: Timg9, alt: '' },
      ];
    
      const videos = [
        // { src: Tvideo1, alt: '' },
        { src: Tvideo2, alt: '' },
        { src: Tvideo3, alt: '' },
      ];
    
      return (
        <div className="trinco-gallery-container">
          <h1 className="trinco-gallery-heading">Trincomalee Gallery</h1>
          
          <div className="image-gallery">
            {images.map((image, index) => (
              <img 
                key={index} 
                src={image.src} 
                alt={image.alt} 
                className="trinco-gallery-image" 
              />
            ))}
          </div>
    
          <div className="video-gallery">
            {videos.map((video, index) => (
              <video key={index} controls className="trinco-gallery-video">
                <source src={video.src} type="video/mp4" />
                {video.alt}  {/* Providing a text alternative for the video */}
              </video>
            ))}
          </div>
        </div>
      );
    };

export default trincomalee
