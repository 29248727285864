import React from 'react';
import './cookiePolicy.css';
import backgroundVideo from '../../assets/videos/policyBG.mp4';

const cookiePolicy = () => {
  return (
    <div className="cookie-container">
      <div className="video-banner">
        <video className="video-banner__video" autoPlay muted loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-banner__overlay">
          <h2 className="cookie-header">
            Cookie Policy
          </h2>
        </div>
      </div>
      <div className="cookie-section">
        <h3 className='header2'>1. Introduction </h3>
        <p className="cookie-paragraph">
        Our website, [www.sitaramatravels.com] ("Website"), uses cookies to enhance your browsing experience, analyse website traffic, 
        and assist with our marketing efforts. This Cookie Policy explains what cookies are, how we use them, and your choices regarding 
        cookies.
        </p>
        <h3 className='header2'>2. What Are Cookies?</h3>
        <p className="cookie-paragraph">
        Cookies are small text files that are placed on your device (computer, smartphone, or tablet) when you visit a website. They help 
        the website remember your actions and preferences (such as login information and language preferences) over a period of time so 
        you don’t have to re-enter them whenever you return to the site or browse from one page to another.
        </p>
        <h3 className='header2'>3. Types of Cookies We Use</h3>
        <ul className='listterms'>
        <li ><b>•	Essential Cookies:</b> These cookies are necessary for the website to function and cannot be switched off in our systems. 
        They are usually only set in response to actions you take, such as setting your privacy preferences, logging in, or filling out 
        forms.</li>
        <li><b>•	Performance Cookies:</b> These cookies help us understand how visitors interact with our website by collecting and reporting 
          information anonymously. This allows us to improve the website's performance and user experience. </li>
        <li><b>•	Functional Cookies:</b> These cookies enable the website to provide enhanced functionality and personalization. They may 
        be set by us or by third-party providers whose services we have added to our pages.</li>
        <li><b>•	Functional Cookies:</b>These cookies are used to deliver advertisements that are more relevant to you and your interests. 
        They may be set by us or by third-party providers. They remember that you have visited a website and may share this information 
        with other organizations, such as advertisers.</li>
        </ul>
        <h3 className='header2'>4. How We Use Cookies</h3>
        <p className="cookie-paragraph">
        We use cookies to:
        </p>
        <ul className='listterms'>
        <li>•	Improve the functionality and performance of our website.</li>
        <li>•	Analyse how our website is used and monitor its performance.</li>
        <li>•	Provide personalized content and advertisements.</li>
        <li>•	Remember your preferences and settings.</li>
        </ul>
        <h3 className='header2'>5. Third-Party Cookies </h3>
        <p className="cookie-paragraph">
        In addition to our own cookies, we may also use third-party cookies. These cookies are set by external services or providers 
        that we use to enhance our website’s functionality. For example, we may use Google Analytics to track website usage or integrate 
        social media features that set their own cookies.
        </p>
        <h3 className='header2'>6. Managing Cookies</h3>
        <p className="cookie-paragraph">
        You can manage your cookie preferences through your browser settings. Most browsers allow you to refuse cookies or delete them 
        from your device. Please note that blocking or deleting cookies may impact the functionality and performance of our website.
        </p>
        <p className="cookie-paragraph">
        To learn more about managing cookies, you can visit:
        </p>
        <ul className='listterms'>
        <li>•	Google Chrome</li>
        <li>•	Mozilla Firefox</li>
        <li>•	Microsoft Edge.</li>
        <li>•	Microsoft Edge</li>
        </ul>
        <h3 className='header2'>7. Updates to This Cookie Policy</h3>
        <p className="cookie-paragraph">
        We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or 
        regulatory reasons. We encourage you to review this policy periodically for any changes.
        </p>
        <h3 className='header2'>8. Contact Us</h3>
        <p className="cookie-paragraph">
        <b>If you have any questions about this Cookie Policy or how we use cookies, please contact us at:</b>
        </p>
        <p className="cookie-paragraph">
        Sita Rama Travels and Tours Pvt Ltd
        </p>
        <p className="cookie-paragraph">
        Email: [info@sitaramatravels.com]
        </p>
        <p className="cookie-paragraph">
        Phone: +(94) 744 435 434  / +(94) 117 760 111
        </p>
        <p className="cookie-paragraph">
        Address: 41/5, DS Fonseka Road, Colombo 05. 
        </p>
      </div>
    </div>
  )
}

export default cookiePolicy
