import React from 'react';
import './mannar.css';
import Mimg1 from '../../assets/images/Mannar/DSC_5506.JPG';
import Mimg2 from '../../assets/images/Mannar/DSC_5507.JPG';
import Mimg3 from '../../assets/images/Mannar/DSC_5509.JPG';
import Mimg4 from '../../assets/images/Mannar/DSC_5510.JPG';
import Mimg5 from '../../assets/images/Mannar/DSC_5515.JPG';
import Mimg6 from '../../assets/images/Mannar/DSC_5516.JPG';
import Mimg7 from '../../assets/images/Mannar/DSC_5518.JPG';
import Mimg8 from '../../assets/images/Mannar/DSC_5519.JPG';
import Mimg9 from '../../assets/images/Mannar/DSC_5522.JPG';
import Mimg10 from '../../assets/images/Mannar/DSC_5525.JPG';
import Mimg11 from '../../assets/images/Mannar/DSC_5534.JPG';
import Mvideo1 from '../../assets/images/Mannar/DSC_5511.MOV';

const Mannar = () => {
  const images = [
    { src: Mimg1, alt: '' },
    { src: Mimg2, alt: '' },
    { src: Mimg3, alt: '' },
    { src: Mimg4, alt: '' },
    { src: Mimg5, alt: '' },
    { src: Mimg6, alt: '' },
    { src: Mimg7, alt: '' },
    { src: Mimg8, alt: '' },
    { src: Mimg9, alt: '' },
    { src: Mimg10, alt: '' },
    { src: Mimg11, alt: '' },
  ];

  const videos = [
    { src: Mvideo1, alt: '' },
  ];

  return (
    <div className="mannar-gallery-container">
      <h1 className="mannar-gallery-heading">Mannar Gallery</h1>
      
      <div className="image-gallery">
        {images.map((image, index) => (
          <img 
            key={index} 
            src={image.src} 
            alt={image.alt} 
            className="mannar-gallery-image" 
          />
        ))}
      </div>

      <div className="video-gallery">
        {videos.map((video, index) => (
          <video key={index} controls className="mannar-gallery-video">
            <source src={video.src} type="video/mp4" />
            {video.alt}  {/* Providing a text alternative for the video */}
          </video>
        ))}
      </div>
    </div>
  );
};

export default Mannar;
