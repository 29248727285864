import React from 'react';
import Terms from '../components/TermsAndConditions/termsAndConditions';


const TermsPolicyPage = () => {
  return (
    <div>
      <Terms />
    </div>
  );
}

export default TermsPolicyPage;
