import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaLinkedin, FaPhone, FaEnvelope, FaGlobe, FaMapMarkerAlt } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import './footer2.css';
import logo from '../../assets/images/Logo Final -02 (1).png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import otherLogo from '../../assets/images/sltda_logo.png';
import otherLogo2 from '../../assets/images/WTTC-SafeTravels-R-Stamp.png';
//import otherLogo3 from '../../assets/images/IATA-logo-3CE0FA8A11-seeklogo.com.png';

const Footer2 = () => {
    return (
        <>
            <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="column">
                            <div className="logoDiv">
                                <img src={logo} alt='Footer Logo' />
                            </div>
                            <div>
                                <p>
                                Sita Rama Travel & Tours is a toruist board approved travel agency based in Sri Lanka. We have obtained safe and secure certification !. 
                                </p>
                                {/* <p>
                                    Reg No:
                                </p> */}
                            </div>
                            
                            <div className="footer-icons">
                                <a href="https://www.facebook.com/profile.php?id=61561780684547&mibextid=ZbWKwL " target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                                <a href="https://x.com/SitaramaTravals?t=9-UVrAFnHMBBbBEoHfBokw&s=09" target="_blank" rel="noopener noreferrer"><FaSquareXTwitter /></a>
                                <a href="https://www.facebook.com/profile.php?id=61561780684547&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                                <a href="https://www.linkedin.com/in/sitarama-travels-1637a9317?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                                <a href="https://www.tiktok.com/@sitarama.travels " target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
                            </div>
                        </div>
                        <div className="column">
                            <h5><u>Quick Links</u></h5>
                            <ul>
                                <li className="nav-item">
                                    <a href="/about-us">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/whysitarama">Why Sita Rama</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/tripAd">Trip Advisor</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/contact-us-page">Contact Us</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/beforeYourTrip">Before Your Trip</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/pkg-inquiry-form">Make a Inquiry</a>
                                </li>
                            </ul>
                        </div>
                        <div className="column">
                            <h5><u>Other Links</u></h5>
                            <ul>
                                <li className="nav-item">
                                    <a href="/termspolicy">Terms & Conditions</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/privacypolicy">Privacy Policy</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/refundpolicy">Refund Policy</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/cookiepolicy">Cookie policy</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/faq">FAQ's</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/gallery">Our Gallery</a>
                                </li>
                            </ul>
                        </div>
                        <div className="column">
                            <h5><u>Contact Details</u></h5>
                            <table>
                                <tr>
                                    <td><FaPhone /></td>
                                    <td>(+94) 744 435 434</td>
                                </tr>
                                <tr>
                                    <td><FaPhone /></td>
                                    <td>(+94) 117 760 111</td>
                                </tr>
                                <tr>
                                    <td><FaEnvelope /></td>
                                    <td>info@sitaramatravels.com</td>
                                </tr>
                                <tr>
                                    <td><FaGlobe /></td>
                                    <td>www.sitaramatravel.com</td>
                                </tr>
                                <tr>
                                    <td><FaMapMarkerAlt /></td>
                                    <td><b>Head Office</b>SitaRama Travels (PVT) LTD, No: 41/5, D.S.Fonseka Road, Colombo 5, Sri Lanka.</td>
                                </tr>
                                <tr>
                                    <td><FaMapMarkerAlt /></td>
                                    <td><b>Cooperative Office</b>Level 27, Cinnamon Life, "The Offices", 130 Glennie St, Colombo 02, Sri Lanka.</td>
                                </tr>
                            </table>
                        </div>
                        <div className="column">
                        <div className="otherLogoDiv1">
                                <img src={otherLogo} alt='Other Logo' />
                        </div>
                        <div className="otherLogoDiv2">
                                <img src={otherLogo2} alt='Other Logo' />
                        </div>
                        <div className="otherLogoDiv3">
                                {/* <img src={otherLogo3} alt='Other Logo' /> */}
                        </div>
                        </div>
                    </div>
                </div>
                <div className='Last-footer'>
                    <hr />
                    <div>
                        <p>© All rights reserved by Sitha Rama Travels</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer2;
