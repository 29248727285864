import React from 'react';
import DestinationList from '../components/DestinationList/destinationList';
import DestinationBanner from '../components/DestinationBanner/destinationBanner';
import AboutUsMap from '../components/AboutUsMap/aboutusmap';


const Destination = () => {
  return (
    <div>
      <DestinationBanner />
      <DestinationList />
      <AboutUsMap />
    </div>
  )
}

export default Destination
