import React, { useState } from 'react';
import './galleryPage.css';
import Mannar from '../../components/Mannar/mannar';  
import Trincomalee from '../../components/Trincomalee/trincomalee';
import Chilaw from '../../components/Chilaw/chilaw';

const GalleryPage = () => {
  const [selectedDestination, setSelectedDestination] = useState(null);

  const destinations = [
    { name: 'Mannar', component: Mannar },
    { name: 'Trincomalee', component: Trincomalee },
    { name: 'Nuwara Eliya', component: null },
    { name: 'Ella', component: null },
    { name: 'Galle', component: null },
    { name: 'Chilaw', component: Chilaw },
    { name: 'Colombo', component: null },
    { name: 'Kandy', component: null },
    { name: 'Dambulla', component: null },
  ];

  const handleClick = (destination) => {
    setSelectedDestination(destination);
  };

  return (
    <div className="gallery-container">
      <div className="destination-section">
        <h1 className="gallery-heading">Our Gallery</h1>
        <div className="destination-list">
          {destinations.map((destination, index) => (
            <div 
              key={index} 
              className="destination-item" 
              onClick={() => handleClick(destination)}
            >
              {destination.name}
            </div>
          ))}
        </div>
      </div>

      <div className="gallery-section">
        {selectedDestination && selectedDestination.component && (
          <div className="destination-gallery">
            <div className="image-gallery">
              {/* Replace this with your image gallery component or code */}
              <selectedDestination.component key={`${selectedDestination.name}-images`} type="images" />
            </div> 
          </div>
        )}
      </div>
    </div>
  );
};

export default GalleryPage;
