import React from 'react';
import './blog.css';

function BlogList ({ id, title, description, link, src }) {
    return (
        <div className="blog-post" key={id}>
            <img src={src} alt={title} />
            <div className="blog-content">
                <h3>{title}</h3>
                <p>{description}</p>
                <a href={link} className="read-more">Read more</a>
            </div>
        </div>
    );
}

export default BlogList;