import React, { useEffect } from 'react';

const GoogleTranslate = () => {
  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src ="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&hl=en";
      document.body.appendChild(script);
    };

    const googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement({
          pageLanguage: 'en',
          includedLanguages: 'en,hi,ta,ur,mr,gu', 
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        }, 'google_translate_element');


        customizeGoogleTranslateWidget();
      }
    };

    // Function to customize the text
    const customizeGoogleTranslateWidget = () => {
      const selectElement = document.querySelector('.goog-te-combo');
      if (selectElement) {
        selectElement.options[0].text = 'Select your language'; 
      }
    };

    addGoogleTranslateScript();

    window.googleTranslateElementInit = googleTranslateElementInit;

    // Observe changes to the DOM and apply customization again when the dropdown changes
    const observer = new MutationObserver(() => customizeGoogleTranslateWidget());
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect(); 
    };
  }, []);

  return <div id="google_translate_element" />;
};

export default GoogleTranslate;
