import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { IoLocationSharp } from 'react-icons/io5'; // Import the icon
import 'leaflet/dist/leaflet.css';

// Convert react-icon to a Leaflet icon
const iconUrl = (icon) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      ${icon}
    </svg>`;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

// Get the SVG path for IoLocationSharp
const ioLocationSharpSvg = '<path d="M12 2a5 5 0 0 1 5 5c0 2.5-2.5 7.5-5 11-2.5-3.5-5-8.5-5-11a5 5 0 0 1 5-5zM12 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>';

// Define custom icon using IoLocationSharp
const customIcon = new L.Icon({
  iconUrl: iconUrl(ioLocationSharpSvg),
  iconSize: [24, 24], // Size of the icon
  iconAnchor: [12, 24], // Anchor point of the icon
  popupAnchor: [0, -24] // Popup anchor point
});

// Define the location coordinates and names
const locations = [
  { name: 'Manavari Kovil - Chillaw', coordinates: [8.8055, 79.9762] },
  { name: 'Munneshwaram Kovil Chilaw', coordinates: [8.7625, 79.9722] },
  { name: 'Adams Bridge', coordinates: [9.3688, 79.7337] },
  { name: 'Sita Eliya Temple', coordinates: [6.9723, 80.6340] },
  { name: 'Ussangoda - Galle', coordinates: [6.0178, 80.3764] },];

const SriLankaMap = () => {
  const sriLankaCoordinates = [7.8731, 80.7718]; // Center of Sri Lanka

  return (
    <MapContainer center={sriLankaCoordinates} zoom={7} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
      />
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={location.coordinates}
          icon={customIcon}
        >
          <Popup>{location.name}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default SriLankaMap;
