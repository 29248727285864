// import React, { useState } from 'react';
import React from 'react';
import '../App.css';
import { Helmet } from 'react-helmet';

import VideoSection from '../components/VideoSection';
import packagesData from '../data/packages';
import PackageList from '../components/PackageList';
import Heading from '../components/Heading';
import '../components/Blog/blog.css';
import backgroundVideo from '../assets/videos/BG1.mp4';
import blog from '../data/blog';
import BlogList from '../components/Blog/BlogList';
import HotelSlider from '../components/HotelSlider/hotelslider';
import Imagerotate from '../components/Imagerotate/Imagerotate';
import PkgInquiryForm from '../components/PkgInquiryForm/PkgInquiryForm';
import Description2 from '../components/Description2';
import ReviewSlider from '../components/ReviewSlider/reviewSlider';

// import AudioPlayer from '../components/AudioPlayer/AudioPlayer';
// import HomeBgAudio from '../assets/audio/home-bg-audio.mp3';


function Home () {
  // const [play, setPlay] = useState(false);

  // const togglePlay = () => {
  //   setPlay(prevPlay => !prevPlay);
  // };
  // const [isHorizontal, setIsHorizontal] = useState(false);


    return (
        <>
        {/* video section imported */}
        {/* <AudioPlayer src={HomeBgAudio} play={play} togglePlay={togglePlay}/> */}

        <VideoSection />
        {/* <PkgInquiryForm  layout ='horizontal'/> */}
        <Description2 />
        <div className='packages'>
        <Heading/>
          <div className='packages__container'>
            <div className='packages__wrapper'>
              <ul className='packages__list'>
                {packagesData.map(pkg => (
                  <PackageList
                    key={pkg.id}
                    id={pkg.id}
                    src={pkg.image}
                    text={pkg.title}
                    location={pkg.location}
                    duration={pkg.duration}
                    places={pkg.places}
                    shortdescrip={pkg.shortdescrip}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
        <PkgInquiryForm  layout ='horizontal'/>
        {/* <Description2 /> */}
        <Imagerotate/>
        {/* <Clients/> */}
        <ReviewSlider />
        {/* blog list component with video background */}
        <div className="blog-section">
            <video className="background-video" autoPlay loop muted>
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="sectiontitle">
                <h1>ABOUT RAMAYANA TRAIL</h1>
            </div>
            <div className="blog-container">
                {blog.map(post => (
                    <BlogList
                        key={post.id}
                        id={post.id}
                        src={post.image}
                        title={post.title}
                        description={post.description}
                        link={post.link}
                        content={post.content}
                    />
                ))}
            </div>
        </div>
        <HotelSlider />

        <div>
        <Helmet>
          <title>Home - Sita Rama website</title>
          <meta name="description" content="Explore Sri Lanka's Ramayana Trail, a journey of spiritual significance and cultural immersion. Follow the legendary footsteps of Lord Rama, Sita, and Hanuman through ancient temples, verdant forests, and majestic mountains. Let us guide you on this sacred pilgrimage, where history, mythology, and natural beauty converge in harmony." />
          <meta name="keywords" content="sita-rama, sita rama, sitarama, sitarama travels, sita rama travels, ramayan trail" />
        </Helmet>
        {/* <h1>Welcome to Sita Rama Travels</h1>
        <p>This is the home page.</p> */}
        </div>
        </>
    );
}

export default Home;
