// src/components/AudioPlayer/AudioPlayer.js
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { FaWhatsapp } from 'react-icons/fa'; // Import icons from react-icons

import './AudioPlayer.css'; // Import the CSS file

const AudioPlayer = ({ src, play, togglePlay }) => {
  const [userInteracted, setUserInteracted] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (userInteracted) {
      if (play) {
        audioRef.current.play().catch((error) => {
          console.error("Failed to play audio:", error);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [play, userInteracted]);

  const handlePlayPause = () => {
    if (!userInteracted) {
      setUserInteracted(true);
    }
    togglePlay(); // This was previously `togglePlay` instead of `handlePlayPause`
  };

  return (
    <div className="audio-player-container">
      <audio ref={audioRef} loop>
        <source src={src} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <button className="audio-control-button" onClick={handlePlayPause}>
        <FontAwesomeIcon icon={play ? faPause : faPlay} />
      </button>
      <a
        href="https://wa.me/94744435434"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-btn"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};

export default AudioPlayer;
