import React from 'react';
import './insightfulTips.css';
import backgroundVideo from '../../assets/videos/policyBG.mp4';

const InsightfulTips = () => {
  const tips = [
    'Sunscreen, hat, binoculars are a must when you’re in a Safari.',
    'Bring comfy cotton clothes to beat the heat.',
    'For Hill Country, bring your sweaters.',
    'Stay relaxed!! That’s the best tip for the tour.'
  ];

  return (
    <div className="tips-container">
       <div className="video-banner">
        <video className="video-banner__video" autoPlay muted loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-banner__overlay">
          <h2 className="privacy-header">
          Insightful Tips 
          </h2>
        </div>
      </div>
      <ul className="tips-list">
        {tips.map((tip, index) => (
          <li className="tip-item" key={index}>{tip}</li>
        ))}
      </ul>
    </div>
  );
}

export default InsightfulTips;

