import React from 'react'
import './travltips.css';
import video2 from '../../assets/videos/Travel vlog intro - copyright free.mp4';

const Travaltips = () => {
  return (
    <div>
        <section class="banner">
        <video src={video2} muted autoPlay loop type="video/mp4" class="banner-video2"> </video>
        <h1>Useful Tips for Traveling </h1>
        <p class="banner-text2">

          <div className='banner-text2'>
          <br></br>* Smart Packing: Option for lightweight, breathable essentials like sunscreen, insect repellent, a hat, and an umbrella to protect yourself from the sun. <br></br>*Cultural Respect: Embrace local customs by removing your shoes before entering temples and dressing modestly. This shows respect for Sri Lanka’s rich cultural traditions.
        <br></br> *Stay Flexible: Keep an open mind and be ready for spontaneous adventures or changes in your plans. Often, the most memorable experiences come from unplanned detours. <br></br> *Capture the Beauty: Make sure to bring a camera to document the stunning sights. However, always be mindful of photography rules, especially in sacred or religious sites.
        <br></br> *Savor the Flavors: Dive into Sri Lanka’s culinary delights by trying local dishes. It’s a delicious way to immerse yourself in the country’s diverse food culture.
          </div>
    
        </p>

        </section>

    </div>


  )
}
export default Travaltips



