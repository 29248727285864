import React from 'react';
import './termsAndConditions.css';
import backgroundVideo from '../../assets/videos/policyBG.mp4';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <div className="video-banner">
        <video className="video-banner__video" autoPlay muted loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-banner__overlay">
          <h2 className="terms-header">
            Terms & Conditions 
          </h2>
        </div>
      </div>
      <div className="terms-section">
        <p className="terms-paragraph">
        Welcome to www.sitaramatravels.com Sita Rama Travels and Tours provides services to its valued customers subject to the following 
        conditions. Please read and accept the under mentioned conditions and guidelines carefully before you use the services of the 
        website. Access to and use of this website is conditional upon your acceptance of these terms and conditions. Please read the 
        following terms and conditions closely before using our website. If you do not agree with any part of these terms and conditions, 
        you must not use this website.
        </p>
        <p className="terms-paragraph">
        We reserve the right to change these terms and conditions subject to amendment/s of the existing internal & external 
        (Government policies / private institutions policy changes). Any changes will take effect when they are posted on the website 
        and it will be your responsibility to read them on each occasion you visit the site as you will be bound by the latest edition 
        of the terms and conditions.
        </p>
        <p className="terms-paragraph">
        We will use all reasonable endeavors to ensure that the information provided is free from errors and omissions and will correct 
        any errors or omissions once notified. We cannot, however, be held responsible for misinformation that a reasonable person could 
        not have expected us to know. Our opinions are our own. Whilst we rigorously strive for accuracy, these opinions are necessarily 
        subject to some degree. Occasionally, temporary local circumstances -- for example, emergency maintenance work, power cuts, or 
        off-season closure – can make advertised facilities unavailable. We do not take responsibility for consumer comments made in 
        interactive areas of the site
        </p>
        <h3 className='header2'>1. Introduction </h3>
        <p className="terms-paragraph">
        Welcome to Sita Rama Travels and Tours Pvt Ltd. By booking a tour with us, you agree to comply with and be bound by the 
        following terms and conditions.
        </p>
        <h3 className='header2'>2. Booking and Payment  </h3>
        <ul className='listterms'>
        <li >2.1. All bookings are subject to availability.</li>
        <li>2.2. A deposit of 25% of the total tour cost is required at the time of booking. </li>
        <li>2.3. Full payment must be made 30 days before the departure date.</li>
        <li>2.4. Payments can be made via bank transfer, credit card, or any other method specified by us.</li>
        </ul>
        <p className="terms-paragraph">
        All bookings must be made through an authorized representative of the Company. At the time of booking the Company booking 
        form must be completed and submitted together with a deposit of 50% of the total cost of the booking.
        </p>
        <p className="terms-paragraph">
        Your holiday must be paid in full at least 30 days before the departure date. If payment is not received by the due date, the 
        Company reserves the right to cancel the booking and retain the deposit. If you book within 30 days of your departure date 
        payment must be made in full at the time of booking.
        </p>
        <p className="terms-paragraph">
        All special requests, such as dietary requirements, should be noted on the booking form.
        </p>
        <p className="terms-paragraph">
        The Company will provide the service as set out and confirmed in writing.
        </p>
        <h4 className='header3'>GENERAL REMINDER </h4>
        <p className="terms-paragraph">
        The program is based on the availability of the hotel rooms at the time of forwarding the quotation. As such, rooms are subject 
        to availability at the time of booking.
        Due to high occupancy/demand, the hotels accept only confirmed bookings.
        </p>
        <p className="terms-paragraph">
        Once our proposal is accepted, the final confirmation will be forwarded along with the relevant overnight stays and itinerary. 
        We will do our best to book the mentioned hotels or alternate similar category hotels.
        </p>
        <p className="terms-paragraph">
        In case of any price fluctuations due to a change of hotels, we will send a revised quotation.
        </p>
        <h4 className='header3'>HOTEL RULES AND REGULATIONS </h4>
        <p className="terms-paragraph">
        The official check-in time at all hotels will be 12:00 pm to 02:00 pm (depending on the hotel).
        </p>
        <p className="terms-paragraph">
        The official check-out time at all hotels will be 11:00 am.
        </p>
        <p className="terms-paragraph">
        Hotel check-in / check-out times are fixed and there are no guarantees for early/late check-in / out unless paid for.
        </p>
        <h3 className='header2'>3. Cancellation and Refunds </h3>
        <p className="terms-paragraph">
        All arrangements are inclusive. Cancellation must be in writing and sent by you or your representative who made the booking on 
        your behalf. In event a group booking is canceled by the tour leader (representative); each individual will be equally held 
        liable for cancellation costs. Cancellations are timed from when we contact the supplier on your behalf. A delay is normal 
        outside normal working hours.
        </p>
        <p className="terms-paragraph">
        No refund in full or in part will be given for unutilized services in the package(s) once you commence using the product or 
        service. In general, early check-out and no shows are not refunded.
        </p>
        <p className="terms-paragraph">
        Our general refund policy is as follows;
        Number of days in advance cancellation notice received prior to commencement of tour or hotel stay
        </p>
        <h4 className='header3'>CANCELLATION FEE </h4>
        <p className="terms-paragraph">
        3.1. If you cancel your booking, the following cancellation charges will apply:
        </p>
        <ul className='listterms'>
        <li >●	More than 30 days before departure: 10% of the tour cost</li>
        <li>●	15-30 days before departure: 50% of the tour cost </li>
        <li>●	Less than 15 days before departure: 100% of the tour cost</li>
        </ul>
        <p className="terms-paragraph">
        3.2. Refunds will be processed within 14 working days of cancellation.
        </p>
        <h3 className='header2'>4. Changes by Us </h3>
        <p className="terms-paragraph">
        4.1. We reserve the right to make changes to your tour, including changes to accommodation, transportation, and itinerary, if necessary.
        </p>
        <p className="terms-paragraph">
        4.2. In case of significant changes, we will notify you as soon as possible and offer alternative options or a full refund.
        </p>
        <h4 className='header3'>Amendments to the Itinerary by the Passenger </h4>
        <p className="terms-paragraph">
        After confirmation, if the Client wishes to change the dates or nature of Services, an application to do so may be made on the 
        sitarama website and sitarama shall endeavour to accommodate such changes at the least cost to the Client. The Client shall be 
        responsible for all extra costs of such change.
        </p>
        <p className="terms-paragraph">
        No credits or refunds for unused services shall be given if the Client fails to avail him/herself of the confirmed tour arrangements.
        </p>
        <h3 className='header2'>5. Travel Insurance </h3>
        <p className="terms-paragraph">
        5.1. Travel insurance is mandatory for all clients. It is your responsibility to ensure you have adequate coverage for the entire 
        duration of the tour.
        </p>
        <h3 className='header2'>6. Passports and Visas</h3>
        <p className="terms-paragraph">
        6.1. It is your responsibility to ensure that you have a valid passport and the necessary visas for the countries you will be visiting.
        </p>
        <h3 className='header2'>7. Health and Safety</h3>
        <p className="terms-paragraph">
        7.1. You must inform us of any pre-existing medical conditions or dietary requirements at the time of booking.
        </p>
        <p className="terms-paragraph">
        7.2. We are not liable for any illness, injury, or death sustained during the tour.
        </p>
        <h3 className='header2'>8. Liability </h3>
        <p className="terms-paragraph">
        8.1. Sita Rama Travels and Tours Pvt Ltd shall not be liable for any loss, damage, or expense arising from delays, accidents, 
        or any other cause beyond our control.
        </p>
        <h4 className='header3'>GENERAL TERMS AND CONDITIONS </h4>
        <p className="terms-paragraph">
        The rates are net and include all government taxes totaling 18%.
        These rates are subject to current government taxes. If any taxes are revised including VAT, the rates quoted will be revised accordingly.
        Discount for children from 02 to 12 years sharing the parent’s room: 30% per person sharing double room rate.
        </p>
        <p className="terms-paragraph">
        Discount for the third person sharing a triple room: 10% per person sharing a double room rate.
        Please note that the extra bed in a triple room will be a foldable and rollaway bed
        </p>
        <h3 className='header2'>9. Governing Law </h3>
        <p className="terms-paragraph">
        9.1. These terms and conditions are governed by the laws of Sri Lanka. Any disputes will be subject to the jurisdiction of the 
        courts in Sri Lanka.
        </p>
      </div>
    </div>
  )
}

export default TermsAndConditions;
