import React from 'react';
import './aboutusbanner.css';

const AboutUsBanner = () => {
  return (
    <div className="AUbanner">
      <div className="AUbanner-content">
        <div className="AUbanner-text">
          <h1>Our Story</h1>
          <p>Welcome to Sita-Rama Travels & Tours, where we offer a unique experience by bringing the Ramayana 
            epic to life. Our tours connect the rich cultural heritage of ancient India with the breathtaking 
            landscapes of Sri Lanka. We are dedicated to transforming nine key sites along Sri Lanka's Ramayana 
            Trail into spiritual and cultural landmarks, creating unforgettable journeys for our travelers.</p>
      <p>Using cutting-edge technology like AI, augmented reality, and virtual reality, we aim to offer an 
        immersive journey through the sacred narrative of the Ramayana. This initiative is endorsed by Swami 
        Govind Dev Giriji Maharaj and supported by prominent leaders from both India and Sri Lanka, fostering 
        stronger ties and promoting religious tourism. </p>
      <p>Our mission is to guide you through this legendary epic, helping you explore significant sites and 
        landmarks intricately woven into the Ramayana’s story. We strive to provide a transformative experience 
        that deepens your connection to this ancient tale while boosting spiritual tourism in the region.</p>
      
        </div>
      </div>
    </div> 
  );
}

export default AboutUsBanner;
