import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './hotelslider.css';
import hotels from '../../data/hotelsliderdata';
import { FaArrowAltCircleRight } from "react-icons/fa";


const HotelSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="slider-container custom-slick-slider">
            <div className='sectiontitle'><h1>FEATURED HOTELS </h1></div>
            <Slider {...settings}>
                {hotels.map((hotel, index) => (
                    <div key={index} className="slide">
                        <div className="circle">
                            <Link to={`/hotel/${hotel.name}`}>
                                <img src={hotel.img1} alt={hotel.name} className="hotel-image" />
                            </Link>
                        </div>
                        <div className="info">
                            <h3>
                                <Link to={`/hotel/${hotel.name}`} className="hotel-link">
                                    {hotel.name}
                                </Link>
                                <span className='goicon'><FaArrowAltCircleRight /></span>
                            </h3>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default HotelSlider;
