import React from 'react';
import './aboutusmap.css';
import mapVideo from '../../assets/videos/Mapvideo.mp4'

const aboutusmap = () => {
  return (
    <div className="map-series">
          <h2>Map of Our Series</h2>
          <video src={mapVideo} autoPlay loop muted alt="Map of Our Series" />
        </div>
  )
}

export default aboutusmap
