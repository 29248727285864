import React from 'react';
import bannerImage from '../../assets/images/about-us-banner1.jpg';
import './AboutUsBannerSection.css'


function AboutUsBannerSection() {
  return (
    <div className='about-us-banner-section' style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className="about-us-banner-content">
        <h1>About Us</h1>
      </div>
      
    </div>
  )
}

export default AboutUsBannerSection;
