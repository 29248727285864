import React,  {useState, useEffect}  from 'react';
import './PackageList.css';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import  Button  from '../Button';

function PackageList({ id, src, text, location, duration, places, shortdescrip }) {
  const [button, setButton] = useState(true);

  return (
    <li className='package__list'>
      <Link className='package__list_link' to={`/package/${id}`}>
        <figure className='package__list__pic-wrap'>
          <img src={src} alt="Travel image" className='package__list__img' />
          {/* <div class="middle">
            <p class="text">{places} </p>
          </div> */}
          <div className="middle">
            <ul className="text">
              {places.map((place, index) => (
                <li key={index}>{place}</li>
              ))}
            </ul>
          </div>
          <div className='package__overlay'>
            <div className='package__info'>
              <FaMapMarkerAlt className='package__icon' />
              <span className='package__text'>{location}</span>
            </div>
            <div className='package__info'>
              <FaClock className='package__icon' />
              <span className='package__text'>{duration}</span>
            </div>
          </div>
        </figure>

        <div className='package__list_info'>
          <h5 className='package__list__text'>{text}</h5>
          <p className='package__list__descrip__text'>
            {shortdescrip}
          </p>
          {button && <Button to={`/package/${id}`} buttonStyle='btn--outline' buttonSize='btn--medium'>View More</Button>}
        </div>
      </Link>
    </li>
  );
}

export default PackageList;