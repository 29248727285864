import React from 'react';
import './visionmission.css';

const visionmission = () => {
  return (
    <div className="vision-mission">
          <div className="column">
            <h2>Our Vision</h2>
            <p>Our vision is to offer travellers a profound and transformative experience, 
                delving into the sacred sites and spiritual narratives that have shaped the cultural landscape of this enchanting island. 
                With expert guidance and immersive encounters, we aim to illuminate the timeless stories of the Ramayana, 
                fostering a deeper connection with history, spirituality, and the natural beauty of Sri Lanka.</p>
          </div>
          <div className="column">
            <h2>Our Mission</h2>
            <p>Our mission is to provide Indian travellers with immersive Ramayana tour packages in Sri Lanka, 
                exploring sacred sites and ancient temples. We aim to deepen your connection with the epic's rich heritage, 
                offering a unique blend of spirituality and culture, ensuring a memorable and enriching travel 
                experience that honours our shared history.</p>
          </div>
        </div>
  )
}

export default visionmission
