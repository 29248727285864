import React from 'react';
import { useParams } from 'react-router-dom';
import hotels from '../../data/hotelsliderdata';
import './hotelDetails.css';

const HotelDetails = () => {
  const { id } = useParams();
  const hotel = hotels.find(h => h.name === id);

  if (!hotel) {
    return <div>Hotel not found</div>;
  }

  return (
    <div className="hotel-details-container">
      <h1>{hotel.name}</h1>
      <img src={hotel.img1} alt={hotel.name} className="hotel-main-image" />
      <p className="hotel-address">{hotel.address}</p>
      <p className="hotel-description">{hotel.description}</p>
      <div className="hotel-images-grid">
        <img src={hotel.img2} alt={`${hotel.name} 2`} className="hotel-small-image" />
        <img src={hotel.img3} alt={`${hotel.name} 3`} className="hotel-small-image" />
        <img src={hotel.img4} alt={`${hotel.name} 4`} className="hotel-small-image" />
        <img src={hotel.img5} alt={`${hotel.name} 5`} className="hotel-small-image" />
        <img src={hotel.img6} alt={`${hotel.name} 6`} className="hotel-small-image" />
      </div>
    </div>
  );
};

export default HotelDetails;
