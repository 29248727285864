
import AraliyaUnawatuna from '../assets/images/Hotels/Araliya unawatuna.jpeg';
import AraliyaUnawatuna1 from '../assets/images/Hotels/Araliya Beach Resort/1.jpeg';
import AraliyaUnawatuna2 from '../assets/images/Hotels/Araliya Beach Resort/2.jpeg';
import AraliyaUnawatuna3 from '../assets/images/Hotels/Araliya Beach Resort/3.jpeg';
import AraliyaUnawatuna4 from '../assets/images/Hotels/Araliya Beach Resort/4.jpeg';
import AraliyaUnawatuna5 from '../assets/images/Hotels/Araliya Beach Resort/5.jpeg';
import BirdsResortHambanthota from '../assets/images/Hotels/Birds resort hambanthota.jpeg';
import BirdsResortHambanthota1 from '../assets/images/Hotels/Birds Resort Hambanathota/1.jpeg';
import BirdsResortHambanthota2 from '../assets/images/Hotels/Birds Resort Hambanathota/2.jpeg';
import BirdsResortHambanthota3 from '../assets/images/Hotels/Birds Resort Hambanathota/3.jpeg';
import BirdsResortHambanthota4 from '../assets/images/Hotels/Birds Resort Hambanathota/4.jpeg';
import BirdsResortHambanthota5 from '../assets/images/Hotels/Birds Resort Hambanathota/5.jpeg';
import HotelAshfordNuwaraeliya from '../assets/images/Hotels/Hotel ashford nuwaraeliya.jpeg';
import HotelAshfordNuwaraeliya1 from '../assets/images/Hotels/Hotel Ashford/1.jpeg';
import HotelAshfordNuwaraeliya2 from '../assets/images/Hotels/Hotel Ashford/2.jpeg';
import HotelAshfordNuwaraeliya3 from '../assets/images/Hotels/Hotel Ashford/3.jpeg';
import HotelAshfordNuwaraeliya4 from '../assets/images/Hotels/Hotel Ashford/4.jpeg';
import HotelAshfordNuwaraeliya5 from '../assets/images/Hotels/hhhhh/asfo6.jfif';
import JkabResortTrincomalee from '../assets/images/Hotels/Jkab resort trincomalee.jpeg';
import JkabResortTrincomalee1 from '../assets/images/Hotels/JKAB Resort/1.jpeg';
import JkabResortTrincomalee2 from '../assets/images/Hotels/JKAB Resort/2.jpeg';
import JkabResortTrincomalee3 from '../assets/images/Hotels/JKAB Resort/3.jpeg';
import JkabResortTrincomalee4 from '../assets/images/Hotels/JKAB Resort/4.jpeg';
import JkabResortTrincomalee5 from '../assets/images/Hotels/JKAB Resort/5.jpeg';
import PalmyrahHouseMannar from '../assets/images/Hotels/Palmyrah house mannar.jpeg';
import PalmyrahHouseMannar1 from '../assets/images/Hotels/Palmyrah House Mannar/1.jpeg';
import PalmyrahHouseMannar2 from '../assets/images/Hotels/Palmyrah House Mannar/2.jpeg';
import PalmyrahHouseMannar3 from '../assets/images/Hotels/Palmyrah House Mannar/3.jpeg';
import PalmyrahHouseMannar4 from '../assets/images/Hotels/Palmyrah House Mannar/4.jpeg';
import PalmyrahHouseMannar5 from '../assets/images/Hotels/Palmyrah House Mannar/5.jpeg';
import QueenHotelKandy from '../assets/images/Hotels/Queen hotel kandy.jpeg';
import QueenHotelKandy1 from '../assets/images/Hotels/QueenHotelKandy/1.jfif';
import QueenHotelKandy2 from '../assets/images/Hotels/QueenHotelKandy/2.jpg';
import QueenHotelKandy3 from '../assets/images/Hotels/QueenHotelKandy/3.jfif';
import QueenHotelKandy4 from '../assets/images/Hotels/QueenHotelKandy/4.jfif';
import QueenHotelKandy5 from '../assets/images/Hotels/QueenHotelKandy/5.jfif';
import Granddeeza1 from '../assets/images/Hotels/granddeeza1.jpg';
import Granddeeza2 from '../assets/images/Hotels/Grandeeza hotel/1.jpeg';
import Granddeeza3 from '../assets/images/Hotels/Grandeeza hotel/2.jpeg';
import Granddeeza4 from '../assets/images/Hotels/Grandeeza hotel/3.jpeg';
import Granddeeza5 from '../assets/images/Hotels/Grandeeza hotel/4.jpeg';
import Granddeeza6 from '../assets/images/Hotels/Grandeeza hotel/5.jpeg';
import Carolina1 from '../assets/images/Hotels/carolina1.jpg';
import Carolina2 from '../assets/images/Hotels/carolina2.JPG';
import Caroline3 from '../assets/images/Hotels/carolina3.JPG';
import Caroline4 from '../assets/images/Hotels/carolina4.JPG';
import Carolina5 from '../assets/images/Hotels/hhhhh/caroline 5.jfif';
import Carolina6 from '../assets/images/Hotels/hhhhh/caroline 6.jfif';
import Ananthaya1 from '../assets/images/Hotels/ananthya1.jpg';
import Ananthaya2 from '../assets/images/Hotels/Anantaya resort and spa/1.jpeg';
import Ananthaya3 from  '../assets/images/Hotels/Anantaya resort and spa/2.jpeg';
import Ananthaya4 from '../assets/images/Hotels/Anantaya resort and spa/3.jpeg';
import Ananthaya5 from '../assets/images/Hotels/Anantaya resort and spa/4.jpeg';
import Ananthaya6 from '../assets/images/Hotels/hhhhh/anantaya 6.jfif';    
import Club from '../assets/images/Hotels/club.jpg';
import Club1 from '../assets/images/Hotels/Club Hotel Dolphin/1.jpeg';
import Club2 from '../assets/images/Hotels/Club Hotel Dolphin/2.jpeg';
import Club3 from '../assets/images/Hotels/Club Hotel Dolphin/3.jpeg';
import Club4 from '../assets/images/Hotels/Club Hotel Dolphin/4.jpeg';
import Club5 from '../assets/images/Hotels/Club Hotel Dolphin/5.jpeg';
import Amaranthe from '../assets/images/Hotels/Am.jpg';
import Amaranthe1 from '../assets/images/Hotels/Amaranthe Bay Resort & Spa/1.jpeg';
import Amaranthe2 from '../assets/images/Hotels/Amaranthe Bay Resort & Spa/2.jpeg';
import Amaranthe3 from '../assets/images/Hotels/Amaranthe Bay Resort & Spa/3.jpeg';
import Amaranthe4 from '../assets/images/Hotels/hhhhh/amaranthe5.jfif';
import Amaranthe5 from '../assets/images/Hotels/hhhhh/amaranthe6.jfif';
import Grand from '../assets/images/Hotels/The_Grand_Kandyan_Hotel.jpg';
import Grand1 from '../assets/images/Hotels/The Grand Kandiyan Hotel/1.jpeg';
import Grand2 from '../assets/images/Hotels/The Grand Kandiyan Hotel/2.jpeg';
import Grand3 from '../assets/images/Hotels/The Grand Kandiyan Hotel/3.jpeg';
import Grand4 from '../assets/images/Hotels/hhhhh/grandkandian5.jfif';
import Grand5 from '../assets/images/Hotels/hhhhh/grandkandian6.jfif';
import Kingbury from '../assets/images/Hotels/kingbury.jfif';
import Kingsbury1 from '../assets/images/Hotels/The Kingsbury/1.jpeg';
import Kingsbury2 from '../assets/images/Hotels/The Kingsbury/2.jpeg';
import Kingsbury3 from '../assets/images/Hotels/The Kingsbury/3.jpeg';
import Kingsbury4 from '../assets/images/Hotels/The Kingsbury/4.jpeg';
import Kingsbury5 from '../assets/images/Hotels/The Kingsbury/5.jpeg';
//import Araliyared from '../assets/images/Hotels/Araliya red nuwaraeliya.jpeg'

const hotels = [
    {
        img1: AraliyaUnawatuna,
        name: 'Araliya Beach Resort',
        address: 'Negombo',
        description: ``,
        img2: AraliyaUnawatuna1,
        img3: AraliyaUnawatuna2,
        img4: AraliyaUnawatuna3,
        img5: AraliyaUnawatuna4,
        img6: AraliyaUnawatuna5
    },
    {
        img1: BirdsResortHambanthota,
        name: 'Birds Resort',
        address: 'Hambanthota',
        description: ``,
        img2: BirdsResortHambanthota1,
        img3: BirdsResortHambanthota2,
        img4: BirdsResortHambanthota3,
        img5: BirdsResortHambanthota4,
        img6: BirdsResortHambanthota5
    },
    {
        img1: Carolina1,
        name: 'Caroline Beach Hotel',
        address: 'Chilaw',
        description: ``,
        img2: Carolina2,
        img3: Caroline3,
        img4: Caroline4,
        img5: Carolina5,
        img6: Carolina6
    },
    {
        img1: HotelAshfordNuwaraeliya,
        name: 'Hotel Ashford',
        address: 'Nuwaraeliya',
        description: ``,
        img2: HotelAshfordNuwaraeliya1,
        img3: HotelAshfordNuwaraeliya2,
        img4: HotelAshfordNuwaraeliya3,
        img5: HotelAshfordNuwaraeliya4,
        img6: HotelAshfordNuwaraeliya5
    },
    {
        img1: JkabResortTrincomalee,
        name: 'Jkab Resort',
        address: 'Trincomelee',
        description: ``,
        img2: JkabResortTrincomalee1,
        img3: JkabResortTrincomalee2,
        img4: JkabResortTrincomalee3,
        img5: JkabResortTrincomalee4,
        img6: JkabResortTrincomalee5
    },
    {
        img1: PalmyrahHouseMannar,
        name: 'Palmyrah House',
        address: 'Mannar',
        description: ``,
        img2: PalmyrahHouseMannar1,
        img3: PalmyrahHouseMannar2,
        img4: PalmyrahHouseMannar3,
        img5: PalmyrahHouseMannar4,
        img6: PalmyrahHouseMannar5
    },
    {
        img1: QueenHotelKandy,
        name: 'Queens Hotel',
        address: 'Kandy',
        description: ``,
        img2: QueenHotelKandy1,
        img3: QueenHotelKandy2,
        img4: QueenHotelKandy3,
        img5: QueenHotelKandy4,
        img6: QueenHotelKandy5
    },
    {
        img1: Granddeeza1,
        name: 'Grandeeza by Galison',
        address: 'Negombo',
        description: ``,
        img2: Granddeeza2,
        img3: Granddeeza3,
        img4: Granddeeza4,
        img5: Granddeeza5,
        img6: Granddeeza6
    },
    {
        img1: Club,
        name: 'Club Hotel Dolphin',
        address: 'Negombo',
        description: ``,
        img2: Club1,
        img3: Club2,
        img4: Club3,
        img5: Club4,
        img6: Club5
    },
    {
        img1: Amaranthe,
        name: 'Amaranthé Bay Resort',
        address: 'Trincomalee',
        description: ``,
        img2: Amaranthe1,
        img3: Amaranthe2,
        img4: Amaranthe3,
        img5: Amaranthe4,
        img6: Amaranthe5
    },
    {
        img1: Grand,
        name: 'The Grand Kandyan Hotel',
        address: 'Kandy',
        description: ``,
        img2: Grand1,
        img3: Grand2,
        img4: Grand3,
        img5: Grand4,
        img6: Grand5
    },
    {
        img1: Kingbury,
        name: 'The Kingsbury',
        address: 'Colombo',
        description: ``,
        img2: Kingsbury1,
        img3: Kingsbury2,
        img4: Kingsbury3,
        img5: Kingsbury4,
        img6: Kingsbury5
    },
    // {
    //     img1: Araliyared,
    //     name: 'Araliya red',
    //     address: 'Nuwaraeliya',
    //     description: ``,
    //     img2: Araliyared,
    //     img3: Araliyared,
    //     img4: Araliyared
    // },
    {
        img1: Ananthaya1,
        name: 'Anantaya Resort and Spa',
        address: 'Chillaw',
        description: ``,
        img2: Ananthaya2,
        img3: Ananthaya3,
        img4: Ananthaya4,
        img5: Ananthaya5,
        img6: Ananthaya6
    },
    // Add more hotel objects as needed
];

export default hotels;