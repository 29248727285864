import React from 'react'
import'./review.css'
import pic1 from '../../assets/images/pic1.jpg';
import pic2 from '../../assets/images/pic2.jpg';
import pic3 from '../../assets/images/pic3.jpg';
import video2 from "../../assets/videos/video1.mp4";

const Review = () => {
  return(
    <div className='header'> 

      <div className='recontainer'>
        <div className='recontainer-left'>
        <h1>Read What Our Customers Love about Us </h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Iusto sint voluptatem et reiciendis totam laudantium vel amet officia architecto, consequuntur, inventore alias ullam. Omnis dicta voluptatum deserunt culpa facilis aliquid?
        </p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Iusto sint voluptatem et reiciendis totam laudantium vel amet officia architecto, consequuntur, inventore alias ullam. Omnis dicta voluptatum deserunt culpa facilis aliquid?
        </p>
        <button>Read Our Sucess Stories</button>
        <video src={video2} unmuted autoPlay loop type="video2/mp4" className='video2'></video>
        </div>
        <div className='recontainer-right'>
          <div className='recard'>
            <img src={pic1} />
            <div className='recard_content'>
          <div className='recard_details'>
            <p>"We have had a lovely time in Sri Lanka, visiting the many locations with our driver/tour guide Harsha. He has been very knowledgeable about many aspects of the country" </p>
            <h4> - Sri Suvamigi</h4>
          </div>
          </div>
          </div>

          <div className='recard'>
            <img src={pic2} />
            <div className='recard_content'>
              <div className='recard_details'>
                <p>" Our holiday started with an early pick up from Colombo airport, we were greeted with a name card by the tour company representative and our guide for the 2 weeks Upul,"</p>
                <h4> - Ravi Shasithri</h4>
              </div>
            </div>
          </div>
          <div className='recard'>
             <img src={pic3} />
             <div className='recard_content'>
              <div className='recard_details'>
                <p>"This tour was intended to be a group tour, but unfortunately this ended up being a private tour as Sri Lanka is still waiting for
                   much of the tourism to come back.  "</p>
                <h4> - Ram prasanth Guru</h4>
              </div>
            </div>
          </div>
          </div>  
    </div>
    </div>
  )
}

export default Review